import { get, post, baseURL } from "@/utils/request.js";

export const getUsers = data => {
    const url = "/charging/user/getTUserPageList";
    return post(url, data);
};

//得到下拉列表
export const getSelectList = (data = null) => {
    return post("/charging/tDot/getTDot", data);
};
/**
 * 添加用户
 * @param {*} data
 */
export const add = data => {
    const url = "/charging/user/insertUser";
    return post(url, data);
};

/**
 * 删除用户
 * @param {*} data
 */
export const del = data => {
    const url = "/charging/user/deleteTUser";
    return post(url, data);
};
/**
 * 用户详情
 * @param {*} data
 */
export const detail = data => {
    const url = "/charging/user/selectTUserById";
    return get(url, data);
};

/**
 * 管理员修改用户信息
 * @param {*} data
 */
export const edit = data => {
    const url = "/charging/user/updateTUser";
    return post(url, data, { format: false });
};

/**
 * 承包区列表
 * @param {*} data
 */
export const contractListDrop = data => {
    const url = "/charging/ContractArea/selectByDotId";
    return get(url, data);
};

/**
 * 上传头像
 * @param {*} data
 */
export const uploadAvatar = data => {
    const url = "/charging/upload/uploadpictures";
    return post(url, data);
};

/**
 * 导入时如果有重复数据时调用的接口
 * @param {*} data
 * @returns
 */
export const isCover = data => {
    const url = "/charging/user/insertCoverUser";
    return post(url, data);
};

export const exportUrl = `${baseURL}/charging/user/userExport`;
/**
 * 导入时如果有重复数据时调用的接口
 * @param {*} data
 * @returns
 */
export const resetPassword = data => {
    const url = "/charging/user/resetPassword";
    return get(url, data);
};
