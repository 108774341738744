import { createRouter, createWebHistory } from "vue-router";
import { ElMessage as Message, ElMessageBox } from "element-plus";
import store from "@/store";

import { get } from "@/utils/request.js";

//其他
import home from "@/views/home.vue";
import basic from "./basic/index.js";
import bill from "./bill/index.js";
import selfCenter from "./self-center/index.js";
import cost from "./cost/index.js";
import finance from "./finance/index.js";
import materiel from "./materiel/index.js";
import reportForm from "./report-form/index.js";
import financialManagement from "./financial-management/index.js";
import reconciliationHQ from "./reconciliationHQ/index.js";
import renew from "./renew/index.js";
import personalManagement from "./personal-management/index.js";
import claimsManagement from "./claims-management/index.js";
import commission from "./commission/index.js";
import incomeExpenditure from "./income-expenditure/index.js";
import salary from "./salary/index.js";

const routes = [
    {
        name: "login",
        path: "/login",
        component: () => import(/* webpackChunkName: "login" */ "@/views/login.vue"),
        meta: { title: "网站登录" },
    },
    {
        name: "release",
        path: "/release",
        component: () => import(/* webpackChunkName: "login" */ "@/views/release.vue"),
        meta: { title: "网站服务协议" },
    },
    {
        name: "register",
        path: "/register",
        component: () => import(/* webpackChunkName: "login" */ "@/views/register/index.vue"),
        meta: { title: "网站注册" },
    },
    {
        name: "doc",
        path: "/doc",
        component: () => import(/* webpackChunkName: "login" */ "@/views/register/doc.vue"),
        meta: { title: "网站服务协议" },
    },
    {
        name: "home",
        path: "/",
        component: home,
        redirect: "/dashboard",
        meta: { title: "工作台" },
        children: [
            {
                name: "dashboard",
                path: "/dashboard",
                component: () => import(/* webpackChunkName: "dashboard" */ "@/views/dashboard.vue"),
                meta: { title: "工作台" },
            },
            ...basic,
            ...finance,
            ...bill,
            ...cost,
            ...materiel,
            ...reportForm,
            ...financialManagement,
            ...selfCenter,
            ...reconciliationHQ,
            ...renew,
            ...personalManagement,
            ...claimsManagement,
            ...commission,
            ...incomeExpenditure,
            ...salary,
        ],
    },
    {
        name: "nSwap",
        path: "/swap",
        component: () => import(/* webpackChunkName: "swap" */ "@/views/swap.vue"),
        meta: { title: "Change Token" },
    },
];

const router = createRouter({ history: createWebHistory(process.env.VUE_APP_ROUTER_BASE_URL), routes, strict: true }); // applies to all routes

const addCache = to => {
    var route = store.state.cacheList;
    var tabs = store.state.pageTabs;
    if (!["login", "dashboard"].includes(to.name)) {
        store.dispatch("createWebSocket");
    }
    if (route.includes(to.name)) {
        store.commit(
            `pageTabs`,
            store.state.pageTabs.map(v => ({
                ...v,
                query: to.name == v.name ? to.query : v.query,
                params: to.name == v.name ? to.params : v.params,
            }))
        );
    } else {
        if (!["dashboard"].includes(to.name)) {
            if (new Date(store.state.userInfo.expireTime) > new Date()) {
                route.push(to.name);
                tabs.push({ title: to.meta.title, name: to.name, path: to.path, query: to.query, params: to.params });
                store.commit(`pageTabs`, tabs);
                store.commit(`cacheList`, route);
            }
        }
    }
};

router.beforeEach(function (to, from, next) {
    Message.closeAll();
    addCache(to);
    window.scrollTo(0, 0);

    const user = store.state.userInfo;
    const excludeRoute = ["/login", "/swap", "/register", "/doc", "/release"];
    const ld = [
        "/login",
        "/swap",
        "/register",
        "/doc",
        "/release",
        "/dashboard",
        "orderPlace",
        "orderPayment",
        "payList",
    ];
    if (excludeRoute.includes(to.path) || user) {
        if (new Date(user.expireTime) < new Date()) {
            if (ld.includes(to.path) || ld.includes(to.name)) {
                next();
            } else {
                Message.error("当前账号已过期,请前往个人中心点击我要续费添加我司客服联系续费");
                next({ path: "/dashboard" });
            }
        } else {
            // next();
            if (to.name === "salaryList") {
                ElMessageBox.prompt("请输入密码", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    inputPattern: /^.{0,50}$/,
                    inputErrorMessage: "最大可输入50个字符",
                })
                    .then(({ value }) => {
                        get("/charging/tSalary/verifyOpenPassword", { password: value })
                            .then(res => {
                                if (res.code === "000000") {
                                    next();
                                } else {
                                    Message({
                                        type: "error",
                                        message: res.message || "密码错误",
                                    });
                                }
                            })
                            .catch(() => {
                                Message({
                                    type: "error",
                                    message: "密码错误",
                                });
                            });
                    })
                    .catch(() => {
                        Message({
                            type: "info",
                            message: "已取消操作",
                        });
                    });
            } else {
                next();
            }
        }
    } else {
        next("/login");
    }
});

// 模块-模块名称对照表
const moduleNameMap = new Map(
    Object.entries({
        basic: "基础资料",
        bill: "账单结算",
        "report-form": "报表系统",
        materiel: "物料管理",
        cost: "成本管理",
        tools: "实用小工具",
        finance: "会计系统",
    })
);

router.afterEach(to => {
    const path = to.path;

    const moduleList = store.state.modules.split(":");
    moduleList.forEach(module => {
        if (path.startsWith(`/${module}`)) {
            document.title = moduleNameMap.get(module) ? "星云 | " + moduleNameMap.get(module) : "星云系统";
            window.name = `zto-${module}`;
        } else {
            window.name = "zto-dashboard";
        }
    });
});
router.onError(error => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    if (isChunkLoadFailed) {
        // 用路由的replace方法，并没有相当于F5刷新页面，失败的js文件并没有从新请求，会导致一直尝试replace页面导致死循环，而用 location.reload 方法，相当于触发F5刷新页面，虽然用户体验上来说会有刷新加载察觉，但不会导致页面卡死及死循环，从而曲线救国解决该问题
        location.reload();
        // const targetPath = $router.history.pending.fullPath;
        // $router.replace(targetPath);
    }
});
export default router;
