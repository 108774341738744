/**
 * @name flat
 * @description 数组扁平化
 * @param {Array} arr 要进行扁平化的数组
 * @param {Number} depth 深度
 */
export const flat = (arr, depth = 1) => {
    if (Array.prototype.flat) {
        return arr.flat(depth);
    } else {
        let res = [],
            depthNum = 0,
            flatMap = item => {
                item.map((element, index, array) => {
                    if (Object.prototype.toString.call(element).slice(8, -1) === "Array") {
                        if (depthNum < depth) {
                            depthNum++;
                            flatMap(element);
                        } else {
                            res.push(element);
                        }
                    } else {
                        res.push(element);
                        if (index === array.length - 1) depthNum = 0;
                    }
                });
            };
        flatMap(arr);
        return res;
    }
};

/**
 * @name flatten
 * @description 数组扁平化
 * @param {Array} arr 要进行扁平化的数组
 */

export function flatten(arr) {
    return arr.reduce((a, item) => {
        let flatArr = [...a, item];
        if (item.children) {
            flatArr = [...flatArr, ...flatten(item.children)];
        }
        return flatArr;
    }, []);
}

/**
 * @name compress
 * @description 压缩字符串
 *
 * @param {String} strNormalString 被压缩的字符串
 */
export const compress = strNormalString => {
    let strCompressedString = "";

    let ht = new Array();
    for (let i = 0; i < 128; i++) {
        ht[i] = i;
    }

    let used = 128;
    let intLeftOver = 0;
    let intOutputCode = 0;
    let pCode = 0;
    let cCode = 0;
    let k = 0;

    for (let i = 0; i < strNormalString.length; i++) {
        cCode = strNormalString.charCodeAt(i);
        k = (pCode << 8) | cCode;
        if (ht[k] != null) {
            pCode = ht[k];
        } else {
            intLeftOver += 12;
            intOutputCode <<= 12;
            intOutputCode |= pCode;
            pCode = cCode;
            if (intLeftOver >= 16) {
                strCompressedString += String.fromCharCode(intOutputCode >> (intLeftOver - 16));
                intOutputCode &= Math.pow(2, intLeftOver - 16) - 1;
                intLeftOver -= 16;
            }
            if (used < 4096) {
                used++;
                ht[k] = used - 1;
            }
        }
    }

    if (pCode != 0) {
        intLeftOver += 12;
        intOutputCode <<= 12;
        intOutputCode |= pCode;
    }

    if (intLeftOver >= 16) {
        strCompressedString += String.fromCharCode(intOutputCode >> (intLeftOver - 16));
        intOutputCode &= Math.pow(2, intLeftOver - 16) - 1;
        intLeftOver -= 16;
    }

    if (intLeftOver > 0) {
        intOutputCode <<= 16 - intLeftOver;
        strCompressedString += String.fromCharCode(intOutputCode);
    }

    return strCompressedString;
};

/**
 * @name compress
 * @description 解压缩字符串
 *
 * @param {String} strNormalString 被解压的字符串
 */
export const decompress = strCompressedString => {
    let strNormalString = "";
    let ht = new Array();

    for (let i = 0; i < 128; i++) {
        ht[i] = String.fromCharCode(i);
    }

    let used = 128;
    let intLeftOver = 0;
    let intOutputCode = 0;
    let cCode = 0;
    let pCode = 0;
    let key = 0;

    for (let i = 0; i < strCompressedString.length; i++) {
        intLeftOver += 16;
        intOutputCode <<= 16;
        intOutputCode |= strCompressedString.charCodeAt(i);

        while (intLeftOver >= 12) {
            cCode = intOutputCode >> (intLeftOver - 12);
            if (typeof (key = ht[cCode]) != "undefined") {
                strNormalString += key;
                if (used > 128) {
                    ht[ht.length] = ht[pCode] + key.substr(0, 1);
                }
                pCode = cCode;
            } else {
                key = ht[pCode] + ht[pCode].substr(0, 1);
                strNormalString += key;
                ht[ht.length] = ht[pCode] + key.substr(0, 1);
                pCode = ht.length - 1;
            }

            used++;
            intLeftOver -= 12;
            intOutputCode &= Math.pow(2, intLeftOver) - 1;
        }
    }
    return strNormalString;
};

/**
 * map转json
 * @param {*} map
 */
export const map2json = map => {
    let obj = {};
    for (let [k, v] of map) {
        obj[k] = v;
    }
    return obj;
};

// 防抖
export function debounce(func, wait, immediate) {
    let timeout, result;

    let debounced = function () {
        const context = this;
        const args = arguments;

        if (timeout) clearTimeout(timeout);
        if (immediate) {
            // 如果已经执行过，不再执行
            const callNow = !timeout;
            timeout = setTimeout(function () {
                timeout = null;
            }, wait);
            if (callNow) result = func.apply(context, args);
        } else {
            timeout = setTimeout(function () {
                func.apply(context, args);
            }, wait);
        }
        return result;
    };

    debounced.cancel = function () {
        clearTimeout(timeout);
        timeout = null;
    };

    return debounced;
}
// 节流
export function throttle(func, wait, options) {
    let timeout,
        context,
        args,
        previous = 0;

    if (!options) options = {};

    let later = function () {
        previous = options.leading === false ? 0 : new Date().getTime();
        timeout = null;
        func.apply(context, args);
        if (!timeout) context = args = null;
    };

    let throttled = function () {
        const now = new Date().getTime();
        if (!previous && options.leading === false) previous = now;
        const remaining = wait - (now - previous);
        context = this;
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            func.apply(context, args);
            if (!timeout) context = args = null;
        } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining);
        }
    };

    throttled.cancel = function () {
        clearTimeout(timeout);
        previous = 0;
        timeout = null;
    };

    return throttled;
}

/**
 * 绑定跨窗口消息监听事件
 * @param {*} eventName 事件名称
 * @param {*} cb 收到消息的处理函数
 */
export const bindChannelEvent = (eventName = "zto-main", cb) => {
    if (window.BroadcastChannel) {
        const channel = new BroadcastChannel(eventName);
        channel.onmessage = ev => {
            if (ev.currentTarget.name === eventName) {
                const data = ev.data;
                if (data.type === "close") {
                    window.close();
                    return false;
                } else {
                    cb && cb(data);
                }
            }
        };
    } else {
        console.warn("您的浏览器不支持 BroadcastChannel");
    }
};
/**
 * 跨窗口的发送消息
 * @param {*} eventName 事件名称
 * @param {*} message 消息内容
 */
export const sendChannelMessage = (eventName = "zto-main", message) => {
    if (window.BroadcastChannel) {
        const channel = new BroadcastChannel(eventName);
        channel.postMessage(message);
        let timer = setTimeout(() => {
            channel.close();
            clearTimeout(timer);
        }, 100);
    } else {
        console.warn("您的浏览器不支持 BroadcastChannel");
    }
};
/**
 * 获取本月初第一天
 * @param {*} year 年份控制
 */
export const getStartMonthTime = year => {
    var data = new Date();
    var Da = new Date(data.getTime());
    var y = Da.getFullYear();
    var m = Da.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    y += year ? year : null;
    return y + "-" + m + "-" + "01" + " " + "00" + ":" + "00" + ":" + "00";
};

/**
 * 判断当前路由是否是子模块的路由
 * @param {*} path 路由路径
 * @returns
 */
export const isModulePage = path => {
    const moduleNameList = ["basic", "bill", "report-form", "financial-management", "materiel", "cost"];
    for (const moduleName of moduleNameList) {
        if (new RegExp(`^/${moduleName}`).test(path)) {
            return true;
        }
    }
    return false;
};
/** 获取当前所有的elMessage的长度
 * @name getElMs
 */

export const getElMs = text => {
    let mes = document.getElementsByClassName("el-message");
    if (mes.length) {
        mes = [...mes].filter(v => {
            return [...v.children][1].innerHTML == text;
        });
    }

    return text ? mes.length : mes;
};
