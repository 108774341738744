export default [
    {
        name: "costQuotationSheet",
        path: "/cost/quotation-sheet",
        component: () => import(/* webpackChunkName: "costQuotation" */ "@/views/cost/quotation-sheet.vue"),
        meta: { title: "发货成本报价维护" },
    },
    {
        name: "costQuotationSheetAdd",
        path: "/cost/quotation-sheet/add",
        component: () => import(/* webpackChunkName: "costQuotation" */ "@/views/cost/quotation-sheet-add.vue"),
        meta: { title: "发货成本报价新增" },
    },
    {
        name: "costQuotationSheetEdit",
        path: "/cost/quotation-sheet/edit",
        component: () => import(/* webpackChunkName: "costQuotation" */ "@/views/cost/quotation-sheet-edit.vue"),
        meta: { title: "发货成本报价修改" },
    },
    {
        name: "costQuotationSheetCopy",
        path: "/cost/quotation-sheet/copy",
        component: () => import(/* webpackChunkName: "costQuotation" */ "@/views/cost/quotation-sheet-copy.vue"),
        meta: { title: "发货成本报价复制" },
    },
    {
        name: "costExpenseType",
        path: "/cost/expense-type",
        component: () => import(/* webpackChunkName: "costExpenseType" */ "@/views/cost/expense-type.vue"),
        meta: { title: "成本费用类型维护" },
    },
    {
        name: "costSummary",
        path: "/cost/summary",
        component: () => import(/* webpackChunkName: "costSummary" */ "@/views/cost/summary.vue"),
        meta: { title: "成本统计总表" },
    },
    {
        name: "costDeliverGoods",
        path: "/cost/deliver-goods",
        component: () => import(/* webpackChunkName: "costDeliverGoods" */ "@/views/cost/deliver-goods.vue"),
        meta: { title: "发货成本报表" },
    },
    {
        name: "costDeliverGoodsSum",
        path: "/cost/deliver-goods/sum",
        component: () => import(/* webpackChunkName: "costDeliverGoods" */ "@/views/cost/deliver-goods-sum.vue"),
        meta: { title: "发货成本汇总" },
    },
    {
        name: "costOther",
        path: "/cost/other",
        component: () => import(/* webpackChunkName: "costOther" */ "@/views/cost/other.vue"),
        meta: { title: "其它成本报表" },
    },
    {
        name: "newCostImport",
        path: "/cost/import",
        component: () => import(/* webpackChunkName: "newCostImport" */ "@/views/cost/import.vue"),
        meta: { title: "成本导入" },
    },
    {
        name: "costRegularDeduction",
        path: "/cost/regular-deduction",
        component: () => import(/* webpackChunkName: "costRegularDeduction" */ "@/views/cost/regular-deduction.vue"),
        meta: { title: "定时扣款" },
    },
    {
        name: "costRegularDeductionAdd",
        path: "/cost/regular-deduction/add",
        component: () =>
            import(/* webpackChunkName: "costRegularDeduction" */ "@/views/cost/regular-deduction-add.vue"),
        meta: { title: "定时扣款新增" },
    },
    {
        name: "costRegularDeductionEdit",
        path: "/cost/regular-deduction/edit",
        component: () =>
            import(/* webpackChunkName: "costRegularDeduction" */ "@/views/cost/regular-deduction-edit.vue"),
        meta: { title: "定时扣款编辑" },
    },
];
