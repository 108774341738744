import { post } from "@/utils/request.js";

/**
 * 新增反馈
 * @param {*} data
 */
export const add = data => {
    const url = "/charging/sysFeedback/addSysFeedback";
    return post(url, data);
};
/**
 * 反馈列表
 * @param {*} data
 */
export const list = data => {
    const url = "/charging/sysFeedback/selectFeedbackPage";
    return post(url, data);
};
/**
 * 反馈列表单条详情
 * @param {*} data
 */
export const detail = data => {
    const url = "/charging/sysFeedback/selectFeedbackById";
    return post(url, data);
};

/**
 * 批量删除
 * @param {*} data
 */
export const del = data => {
    const url = "/charging/sysFeedback/deleteNoticeUpdate";
    return post(url, data);
};

/**
 * 标记为已读
 * @param {*} data
 */
export const readedMessage = data => {
    const url = "/charging/sysFeedback/updateFeedback";
    return post(url, data);
};

/**
 * 标记为全部已读
 * @param {*} data
 */
export const allRead = data => {
    const url = "/charging/sysFeedback/updateFeedbackIsRead";
    return post(url, data);
};

/**
 * 回复
 * @param {*} data
 */
export const reply = data => {
    const url = "/charging/sysFeedback/replySysFeedback";
    return post(url, data);
};
