<template>
    <div class="page-container" :style="minHeight ? `min-height:${mHeight}` : ''">
        <div class="page-container-route" v-if="isRouteSlot">
            <slot name="route"></slot>
        </div>
        <div class="page-container-form" v-if="isFormSlot">
            <div style="width: calc(100% - 140px)"><slot name="form"> </slot></div>
            <div class="page-container-form-search"><slot name="search"></slot></div>
        </div>
        <div class="page-container-content">
            <div class="page-container-content-top" :class="isTableSlot ? 'page-container-content-top1' : 'ding_0'">
                <span v-if="isTableSlot" class="page-container-content-title">列表</span>
                <div v-if="isUtilsSlot" class="d_flex_aItc d_flex"><slot name="utils"></slot></div>
            </div>
            <div v-if="isTableSlot" class="page-container-content-table"><slot name="table"></slot></div>
        </div>
        <div class="page-container-default">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: "PageContainer",
    data() {
        return {};
    },
    props: {
        minHeight: { type: String, default: "" },
    },
    computed: {
        isFormSlot() {
            return this.$slots.form ? true : false;
        },
        isRouteSlot() {
            return this.$slots.route ? true : false;
        },
        isSearchSlot() {
            return this.$slots.search ? true : false;
        },
        isUtilsSlot() {
            return this.$slots.utils ? true : false;
        },
        isTableSlot() {
            return this.$slots.table ? true : false;
        },
        mHeight() {
            return `${this.minHeight}vh`;
        },
    },
};
</script>

<style lang="scss">
.page-container {
    background-color: #f1f1f1;
    border-radius: 8px;
    width: 100%;
    min-height: 82vh;
    &-route {
        margin-bottom: 12px;
    }
    &-search {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        padding: 12px 12px 18px 16px;
        &-title {
            color: #105445;
            font-size: 16px;
        }
    }
    &-form {
        border-radius: 8px 8px 0 0;
        background-color: #fff;
        padding: 12px 24px 12px 24px;
        position: relative;

        &-search {
            position: absolute;
            bottom: 24px;
            right: 24px;
        }
    }
    &-content {
        background-color: #fff;
        &-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 24px;
        }
        &-top1 {
            position: relative;
        }
        &-top1:after {
            content: "";
            width: calc(100% - 48px);
            position: absolute;
            bottom: 0;
            left: 24px;
            display: block;
            height: 1px;
            background-color: #e5e6e8;
        }
        &-title {
            position: relative;
            font-size: 18px;
            padding: 0 0 0 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #1d2129;
        }
        &-title:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 4px;
            height: 100%;
            background-color: #409eff;
            border-radius: 2px;
        }
        &-table {
            position: relative;
            margin: 16px 24px 0 24px;
        }
    }
    &-default {
        background-color: #ffffff;
        padding: 24px 0 24px 0;
        border-radius: 0 0 8px 8px;
    }
}
</style>
