export default [
    {
        name: "logisticsAccountsReceivable",
        path: "/bill/send/logistics/accounts-receivable",
        component: () =>
            import(/* webpackChunkName: "billAdjustment" */ "@/views/bill/send/logistics/accounts-receivable.vue"),
        meta: { title: "物流应收账报表" },
    },
    {
        name: "logisticsSingleProfit",
        path: "/bill/send/logistics/single-profit",
        component: () =>
            import(/* webpackChunkName: "billAdjustment" */ "@/views/bill/send/logistics/single-profit.vue"),
        meta: { title: "物流单票利润表" },
    },
    {
        name: "logisticsFlow",
        path: "/bill/send/logistics/flow",
        component: () => import(/* webpackChunkName: "billAdjustment" */ "@/views/bill/send/logistics/flow.vue"),
        meta: { title: "物流资金流水" },
    },
];
