export default [
    {
        name: "financeAssetCard",
        path: "/finance/fixedAsset/card",
        component: () => import(/* webpackChunkName: "manualRecord" */ "@/views/finance/fixedAsset/card/index.vue"),
        meta: { title: "资产卡片" },
    },
    {
        name: "financeAssetCardAdd",
        path: "/finance/fixedAsset/card/add",
        component: () => import(/* webpackChunkName: "manualRecord" */ "@/views/finance/fixedAsset/card/add.vue"),
        meta: { title: "卡片新增" },
    },
    {
        name: "financeAssetCardEdit",
        path: "/finance/fixedAsset/card/edit",
        component: () => import(/* webpackChunkName: "manualRecord" */ "@/views/finance/fixedAsset/card/edit.vue"),
        meta: { title: "卡片编辑" },
    },
    {
        name: "financeAssetClass",
        path: "/finance/fixedAsset/class",
        component: () => import(/* webpackChunkName: "manualRecord" */ "@/views/finance/fixedAsset/class/index.vue"),
        meta: { title: "资产类别" },
    },
    {
        name: "financeAssetDetails",
        path: "/finance/fixedAsset/details",
        component: () =>
            import(/* webpackChunkName: "manualRecord" */ "@/views/finance/fixedAsset/depreciation/details.vue"),
        meta: { title: "折旧明细表" },
    },
    {
        name: "financeAssetSummary",
        path: "/finance/fixedAsset/summary",
        component: () =>
            import(/* webpackChunkName: "manualRecord" */ "@/views/finance/fixedAsset/depreciation/summary.vue"),
        meta: { title: "折旧汇总表" },
    },
];
