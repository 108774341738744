import { ElNotification as Notification } from "element-plus";
import { PROGRESS, MESSAGE, NOTICE } from "@/data/const.js";
import { sendChannelMessage } from "@/utils/utils.js";
import { getAuth, websocketUrl } from "@/api/comm.js";
import { Socket } from "@/utils/socket.js";

const noticeStatus = {
    success: { icon: "success", color: "#67C23A" },
    warn: { icon: "warning", color: "#E6A23C" },
    error: { icon: "error", color: "#F56C6C" },
};

const updateProgressForHtml = (data, finish = false) => {
    let status = "success";
    if (data.errCount) {
        if (data.totalCount === data.processedCount + data.errCount) {
            status = "warn";
        } else {
            status = "error";
        }
    } else {
        if (data.totalCount === data.processedCount) {
            status = "success";
        } else {
            status = "error";
        }
    }
    if (finish) {
        return `<span>共${data.totalCount}条数据</span>，
                <span>已处理${data.processedCount}条</span>，
                <span>异常${data.errCount}条</span>，
                <span>已花费${data.time}秒</span>
                <i class="el-icon-${noticeStatus[status].icon}" style="color:${noticeStatus[status].color};"></i>`;
    } else {
        return `<span>共${data.totalCount}条数据</span>，
                <span>已处理${data.processedCount}条</span>，
                <span>异常${data.errCount}条</span>，
                <span>已花费${data.time}秒</span>`;
    }
};

export default {
    loginOut(context) {
        context.commit("loginOut");
    },
    getAuth(content) {
        if (!content.getters.userInfo) return;
        const data = { rid: content.getters.userInfo.stationId || "" };
        getAuth(data).then(res => {
            if (res.code == "000000") {
                const moduleList =
                    res.data.map(c => {
                        return c.url;
                    }) || [];
                const modules = moduleList.join(":");
                content.commit("modules", modules);
                let tmpIn = {}; // 左侧菜单
                res.data.map(v => {
                    tmpIn[v.url] = { children: v.children, name: v.name, icon: v.icon };
                });
                const leftMenu = tmpIn;
                content.commit("menusIn", leftMenu);
                content.commit("menus", res.data);
                sendChannelMessage("zto-module", {
                    token: content.state.token,
                    userInfo: content.state.userInfo,
                    modules: modules,
                });
            } else {
                content.commit("modules", "");
                content.commit("menusIn", {});
                content.commit("menus", []);
                sendChannelMessage("zto-module", {
                    token: content.state.token,
                    userInfo: content.state.userInfo,
                    modules: "",
                });
            }
        });
    },

    createWebSocket(ctx) {
        const events = {
            open: function () {
                this.send("register", { userId: ctx.state.userInfo.userId });
            },
            close: function (ev) {
                console.log(ev);
            },
            error: function (ev) {
                console.log("通信失败", ev);
            },
            message: function (data) {
                const type = data.type;
                if (type === NOTICE) {
                    Notification({
                        customClass: "nebula-progress-notification",
                        title: data.data.title || "提示",
                        message: data.data.content || "",
                        duration: 3000,
                        position: "bottom-right",
                    });
                } else if (type === MESSAGE) {
                    if (data.data.isFail) {
                        Notification({
                            type: "error",
                            customClass: "nebula-progress-notification-error",
                            title: data.data.title || "提示",
                            message: data.data.content || "",
                            duration: 0,
                            position: "bottom-right",
                        });
                    } else {
                        Notification({
                            customClass: "nebula-progress-notification",
                            title: data.data.title || "提示",
                            message: data.data.content || "",
                            duration: 3000,
                            position: "bottom-right",
                        });
                    }
                } else if (type === PROGRESS) {
                    const progressType = data.data.optType;
                    const el = document.querySelector(`#sa-progress-${data.data.batchNum}`);
                    if (!el) return;

                    if (progressType === 3) {
                        if (data.data.finish) {
                            const tmp = updateProgressForHtml(data.data, true);
                            el.innerHTML = tmp;
                            const progressInstance = ctx.state.notices.get(data.data.batchNum);
                            let timer = setTimeout(() => {
                                if (progressInstance) {
                                    progressInstance.close();
                                    ctx.commit("removeNotice", data.data.batchNum);
                                }
                                clearTimeout(timer);
                                timer = null;
                            }, 3000);
                        } else {
                            const tmp = updateProgressForHtml(data.data, false);
                            el.innerHTML = tmp;
                        }
                    } else if (progressType === 2) {
                        if (data.data.finish) {
                            const tmp = updateProgressForHtml(data.data, true);
                            el.innerHTML = tmp;
                            const progressInstance = ctx.state.notices.get(data.data.batchNum);
                            let timer = setTimeout(() => {
                                if (progressInstance) {
                                    progressInstance.close();
                                    ctx.commit("removeNotice", data.data.batchNum);
                                }
                                clearTimeout(timer);
                                timer = null;
                            }, 3000);
                        } else {
                            const tmp = updateProgressForHtml(data.data, false);
                            el.innerHTML = tmp;
                        }
                    } else {
                        if (data.data.finish) {
                            const tmp = updateProgressForHtml(data.data, true);
                            el.innerHTML = tmp;
                            const progressInstance = ctx.state.notices.get(data.data.batchNum);
                            let timer = setTimeout(() => {
                                if (progressInstance) {
                                    progressInstance.close();
                                    ctx.commit("removeNotice", data.data.batchNum);
                                }
                                clearTimeout(timer);
                                timer = null;
                            }, 3000);
                        } else {
                            const tmp = updateProgressForHtml(data.data, false);
                            el.innerHTML = tmp;
                        }
                    }
                } else {
                    throw "不支持的消息类型";
                }
            },
        };
        const instance = new Socket(websocketUrl(ctx.state.userInfo.userId), events);
        ctx.commit("websocketInstance", instance);
    },
};
