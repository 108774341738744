export default [
    {
        path: "/commission/salesman/index",
        name: "salesmanList",
        component: () => import("@/views/commission/salesman/index.vue"),
        meta: { title: "业务员提成" },
    },
    {
        path: "/commission/salesman/summary",
        name: "salesmanSummary",
        component: () => import("@/views/commission/salesman/summary.vue"),
        meta: { title: "业务员汇总" },
    },
    {
        path: "/commission/salesman/delivery",
        name: "salesmanDelivery",
        component: () => import("@/views/commission/salesman/delivery.vue"),
        meta: { title: "业务员派件" },
    },
    {
        path: "/commission/maintain/index",
        name: "maintainList",
        component: () => import("@/views/commission/maintain/index.vue"),
        meta: { title: "提成维护" },
    },
];
