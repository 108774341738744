import { typeOf } from "./type.js";
import { HEARTBEAT } from "@/data/const.js";

const DEFAULT = { pingTime: 10 * 60 * 1000, pongTime: 60 * 1000 };

export class Socket {
    constructor(url, events) {
        this.opts = { url, events: {} };
        const types = typeOf(events);
        this.timer = null;
        this.timer1 = null;
        this.instance = this.create(url);
        if (types === "Object") {
            this.opts.events = events;
            this.bindEvents(events);
        } else {
            throw "class Socket的第二个参数必须是Object类型";
        }
    }

    create(url) {
        return new WebSocket(url);
    }

    preParseMessage(key, fn) {
        this.instance.addEventListener(key, ev => {
            const data = ev.data;
            if (data instanceof ArrayBuffer) {
                // binary frame
                throw "websocket 暂不支持接收ArrayBuffer类型的消息";
            } else {
                // text frame
                const tmp = JSON.parse(data);
                if (tmp.type === HEARTBEAT) {
                    if (this.timer) clearTimeout(this.timer);
                    if (this.timer1) clearTimeout(this.timer1);
                    this.timer = null;
                    this.timer1 = setTimeout(() => {
                        this.ping();
                        clearTimeout(this.timer1);
                    }, DEFAULT.pingTime);
                } else {
                    fn(tmp);
                }
            }
        });
    }

    bindEvents(events) {
        const self = this;
        const eventType = ["message", "open", "error", "close"];
        const entries = Object.entries(events);
        for (const [key, value] of entries) {
            if (eventType.includes(key) && typeOf(value) === "Function") {
                if (key === "message") {
                    this.preParseMessage(key, value);
                } else if (key === "open") {
                    this.instance.addEventListener(key, ev => {
                        self.ping();

                        value.bind(self, ev);
                    });
                } else if (key === "close") {
                    this.instance.addEventListener(key, ev => {
                        self.close();
                        if (ev.code !== 1000) {
                            self.reconnect();
                        }
                        value.bind(self, ev);
                    });
                } else {
                    this.instance.addEventListener(key, value.bind(this));
                }
            }
        }
    }

    send(type, data) {
        const varType = typeOf(type);
        if (["String", "Number"].includes(varType)) {
            let tmp = { type };
            if (data) {
                tmp["data"] = data;
            }
            this.instance.send(JSON.stringify(tmp));
        } else {
            throw "Socket实例的send方法中的type参数是必须的";
        }
    }

    close(code, desc) {
        this.instance.close(code, desc);
    }

    ping() {
        const self = this;
        this.send(-1);
        self.timer = setTimeout(() => {
            self.close();
            clearTimeout(self.timer);
            self.timer = null;
            if (self.timer1) {
                clearTimeout(self.timer1);
                self.timer1 = null;
            }
            self.reconnect();
        }, DEFAULT.pongTime);
    }

    reconnect() {
        this.instance = this.create(this.opts.url);
        this.bindEvents(this.opts.events);
    }
}
