import { ElNotification as Notification } from "element-plus";
import Store from "@/store";
import { isArray } from "@/utils/type.js";
import { JSEncrypt } from "jsencrypt";

/**
 * 判断输入的金钱是否符合要求--最多三位小数，若为0则只能输入一个0
 * @param {Number} value 货币的数值
 */
export function checkMoneyFormat(value) {
    const reg = /(^0$)|(^[\d]+([.][\d]{1,3})?$)/;

    return value.length > 1 ? reg.test(value) && parseFloat(value) !== 0 : reg.test(value);
}

/**
 * 判断奇偶性
 * @param {Number} data
 * @returns {Boolean} 奇数：true；偶数：false
 */
export const parity = data => {
    return data & 1;
};

/**
 * 生成uuid
 * @returns {String}
 */
export const uuid = function () {
    let d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
        d += performance.now(); //use high-precision timer if available
    }
    let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
};

/**
 * 表格求和
 * @param {Array} data 求和的数据
 * @param {String} prop 被检测的字段名
 * @param {*} allowList 允许的字段名列表
 * @param {String} prev 放在合计结果前后的字符
 * @param {String} type 标志的位置
 */
export const tableSum = (data = [], prop = "", allowList = [], prev = "", type = "prev") => {
    let sum = 0;
    if (allowList.includes(prop)) {
        const values = data.map(item => Number(item[prop]));
        let dotLen = 0;
        sum = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
                const tmp = String(value);
                if (tmp.includes(".")) {
                    const len = tmp.split(".")[1].length;
                    dotLen = len > dotLen ? len : dotLen;
                }
                return prev + curr;
            } else {
                return prev;
            }
        }, 0);
        if (type === "prev") {
            sum = `${prev}${parseFloat(sum.toFixed(dotLen))}`;
        } else {
            sum += prev;
        }
    } else {
        return "";
    }
    return sum;
};

/**
 * 计算字段的长度并计算其需要的宽度
 * @param {String} str 字段名称
 * @param {Boolean} spec 是否之定义长度
 * @param {Number} width 自定义的宽度
 */
export function calcTableColumnWidth(item, specMap = null) {
    const str = item.label || "";
    const sort = item.sortable || false;
    const width = item.width || 0;
    if (width) {
        return item.width;
    } else {
        if (specMap && Object.keys(specMap).includes(item.label)) {
            return specMap[str] || 100;
        } else {
            const map = { 2: 60, 3: 75, 4: 90, 5: 104, 6: 120, 7: 134, 8: 150, 9: 164, 10: 178, 11: 192, 12: 206 };
            const len = str?.toString()?.length || 4;
            const width = sort ? map[len] + 24 : map[len];
            return width;
        }
    }
}

/**
 * @alias 创建进度窗口
 * @param {*} data 进度数据
 * @param {*} title Notification标题
 */
export function createNoticeModal(data, title) {
    const noticeId = Notification({
        customClass: "sa-progress-notification",
        dangerouslyUseHTMLString: true,
        title: title || "进度",
        message: `<p id="sa-progress-${data.batchNum}"><span>共${data.totalCount}条数据</span>，<span>已处理0条</span>，<span>异常0条</span>，<span>已花费0秒</span><p/>`,
        duration: 0,
        position: "bottom-right",
    });
    Store.commit("addNotices", { key: data.batchNum, value: noticeId });
}

/**
 * 通过url下载文件
 * @param {String} uri 下载路径
 */
export const downloadFile = uri => {
    const eleA = document.createElement("a");
    eleA.href = uri || "";
    const downloadName = uri.split("/").reverse();
    eleA.download = downloadName[0] || null;
    eleA.style.hidden = true;
    const eleAId = `auto-download-ele-${new Date().getTime()}`;
    eleA.id = eleAId;
    document.body.append(eleA);
    eleA.click();
    const timer = setTimeout(() => {
        document.body.removeChild(document.querySelector(`#${eleAId}`));
        clearTimeout(timer);
    }, 100);
};

/**
 * 通过二进制下载文件
 * @param {*} binaryFile 二进制文件
 * @param {*} name 文件名
 */
export const downloadByBinary = (binaryFile, name, mime = "xlsx") => {
    const blobUrl = URL.createObjectURL(binaryFile);
    const eleA = document.createElement("a");
    eleA.href = blobUrl || "";
    const downloadName = `${name}.${mime}`;
    eleA.download = downloadName || null;
    eleA.style.hidden = true;
    const eleAId = `auto-download-ele-${new Date().getTime()}`;
    eleA.id = eleAId;
    document.body.append(eleA);
    eleA.click();
    const timer = setTimeout(() => {
        document.body.removeChild(document.querySelector(`#${eleAId}`));
        URL.revokeObjectURL(blobUrl);
        clearTimeout(timer);
    }, 100);
};

/**
 * 表格合计
 * @param {*} param
 * @param {*} cols 合并的列
 * @param {*} text 第一列显示文字
 * @returns
 */
export const tableSummary = (param, cols = [], text = "合计") => {
    const { columns, data } = param;
    const sums = [];
    columns.forEach((column, index) => {
        if (index === 0) {
            sums[index] = text;
            return;
        } else if (cols.includes(index)) {
            const values = data.map(item => Number(item[column.property]));
            if (!values.every(value => isNaN(value))) {
                let lengths = 0;
                sums[index] = values.reduce((prev, cur) => {
                    const value = Number(cur);
                    if (!isNaN(value)) {
                        const tmp = value.toString().split(".");
                        if (tmp.length == 2 && tmp[1].length > lengths) {
                            lengths = tmp[1].length;
                        }
                        return prev + cur;
                    } else {
                        return prev;
                    }
                }, 0);
                sums[index] = sums[index].toFixed(lengths);
            } else {
                sums[index] = "-";
            }
        } else {
            sums[index] = "-";
        }
    });
    return sums;
};

/**
 * 从时间段选择器的结果中获取开始时间/结束时间
 * @param {*} dateArray 日期数组
 * @param {*} index 下标
 * @returns {String} 格式化后的时间字符串
 */
export const getDate = (dateArray, index = 0) => {
    if (isArray(dateArray)) {
        if (dateArray.length) {
            return dateArray[index];
        } else {
            return null;
        }
    } else {
        return null;
    }
};

/**
 * 使用RSA进行加密字符串
 * @param {*} str
 * @returns
 */
export const encodeRsa = str => {
    if (["", null, undefined].includes(str)) {
        return "";
    } else {
        const key =
            "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCjmb7OaQqZWbrZ3QTmf8vfJNSGyZJvLzkmMpGZdph3rh4Y3Q8loUvhI4I968XmAFbXNNrS2UoP60AFboDNsivadYvTr62jy76c29jlqrSD6wVYGbGvNgOCbOdEQaODdZ1d94+/aFS1RBCc6SX6sBk/02meXlW5vdp6dsv4R4GwJwIDAQAB";
        const encryptTool = new JSEncrypt();
        encryptTool.setPublicKey(key);
        return encryptTool.encrypt(str);
    }
};
// 下载 通过url下载，自定义名称
// @param  {String} url 目标文件地址
// @param  {String} filename 想要保存的文件名称
// export function courseDownload(url, filename) {
//     function getBlob(url) {
//         return new Promise(resolve => {
//             const XML = new XMLHttpRequest();
//             XML.open("GET", url, true);
//             XML.responseType = "blob";
//             XML.onload = () => {
//                 if (XML.status === 200) {
//                     resolve(XML.response);
//                 }
//             };
//             XML.send();
//         });
//     }
//     //下载文件
//     function saveAs(blob, filename) {
//         const eleLink = document.createElement("a");
//         eleLink.style.display = "none";
//         eleLink.download = filename;
//         eleLink.href = window.URL.createObjectURL(blob);
//         document.body.appendChild(eleLink);
//         eleLink.click();
//         document.body.removeChild(eleLink);
//     }
//     // 调用以上方法进行下载
//     getBlob(url).then(blob => {
//         saveAs(blob, filename);
//     });
// }

export const courseDownload = (url, filename) => {
    const x = new window.XMLHttpRequest();
    x.open("GET", url, true);
    x.responseType = "blob";
    x.onload = () => {
        const url = window.URL.createObjectURL(x.response);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
    };
    x.send();
};

/* 判断各个浏览器版本号 */
export const browserVersion = () => {
    function matchVesion() {
        let userAgent = navigator.userAgent;
        let rMsie = /(msie\s|trident.*rv:)([\w.]+)/;
        let rEdge = /(edg)\/([\w.]+)/;
        let rFirefox = /(firefox)\/([\w.]+)/;
        let rOpera = /(opera).+version\/([\w.]+)/;
        let rChrome = /(chrome)\/([\w.]+)/;
        let rSafari = /version\/([\w.]+).*(safari)/;
        let ua = userAgent.toLowerCase();
        var match = rMsie.exec(ua);
        if (match !== null) {
            return { browser: "IE", version: match[2] || "0" };
        }
        var rEmatch = rEdge.exec(ua);
        if (rEmatch !== null) {
            return { browser: "Edge", version: rEmatch[2] || "0" };
        }
        var rFMatch = rFirefox.exec(ua);
        if (rFMatch !== null) {
            return { browser: rFMatch[1] || "", version: rFMatch[2] || "0" };
        }
        var rOMatch = rOpera.exec(ua);
        if (rOMatch !== null) {
            return { browser: rOMatch[1] || "", version: rOMatch[2] || "0" };
        }
        var rCMatch = rChrome.exec(ua);
        if (rCMatch !== null) {
            return { browser: rCMatch[1] || "", version: rCMatch[2] || "0" };
        }
        var rSMatch = rSafari.exec(ua);
        if (rSMatch !== null) {
            return { browser: rSMatch[2] || "", version: rSMatch[1] || "0" };
        }
        if (match !== null) {
            return { browser: "", version: "0" };
        }
    }
    let browserMatch = matchVesion();
    return browserMatch;
};
