<template>
    <el-button
        :loading="loading"
        v-if="!text"
        :type="type"
        :size="size"
        :disabled="disabled"
        :plain="plain ? true : false"
        @click="change"
    >
        {{ name }}
    </el-button>
    <el-text
        :loading="loading"
        v-else
        :type="type == 'default' ? '' : type"
        :size="size"
        :disabled="disabled"
        :plain="plain ? true : false"
        @click="change"
    >
        {{ name }}
    </el-text>
</template>

<script>
export default {
    name: "axiosExport",
    props: {
        action: { type: Function },
        text: { type: Boolean, default: false },
        type: { type: String, default: "default" },
        size: { type: String, default: "default" },
        plain: { type: Boolean, default: false },
        title: { type: String, default: "" },
        successMessage: { type: String, default: "" },
        name: { type: String, default: "导出" },
        data: Object,
        disabled: Boolean,
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        change() {
            const url = this.action;
            this.loading = true;
            url(this.data || {})
                .then(res => {
                    if ([200].includes(res.status) && res.data) {
                        this.loading = false;
                        let binaryData = [];
                        binaryData.push(res.data);
                        let url = window.URL.createObjectURL(new Blob(binaryData));
                        let link = document.createElement("a");
                        link.href = url;
                        const name = this.getFileName(res.headers["content-disposition"]);
                        link.download = decodeURI(this.title || name || "模板.xlsx"); //下载后文件名
                        document.body.appendChild(link);
                        link.click(); //点击下载
                        link.remove(); //下载完成移除元素
                        window.URL.revokeObjectURL(link.href);
                        this.$message.success(this.successMessage || res.message || "导出成功");
                    } else if (res.code == "000000" && res.data) {
                        this.loading = false;
                        let binaryData = [];
                        binaryData.push(res.data);
                        let url = window.URL.createObjectURL(new Blob(binaryData));
                        let link = document.createElement("a");
                        link.href = url;
                        const name = this.getFileName(res.headers["content-disposition"]);
                        link.download = decodeURI(this.title || name || "模板.xlsx"); //下载后文件名
                        document.body.appendChild(link);
                        link.click(); //点击下载
                        link.remove(); //下载完成移除元素
                        window.URL.revokeObjectURL(link.href);
                        this.$message.success(this.successMessage || res.message);
                    } else if (res.code == "000000") {
                        this.loading = false;
                        this.$message.success(this.successMessage || res.message || "导出成功");
                        this.$store.commit("downloadIconBig", true);
                    } else if (res.code == "999999") {
                        this.loading = false;
                        this.$message.error(res.message || "导出失败");
                    } else if (res.status == "failure") {
                        this.loading = false;
                        this.$message.error(res.message || "导出失败");
                    } else {
                        this.loading = false;
                        const fileReader = new FileReader();
                        fileReader.onloadend = () => {
                            const jsonData = JSON.parse(fileReader.result);
                            // 后台信息
                            this.$message.error(jsonData.message || "下载失败");
                        };
                        fileReader.readAsText(res);
                    }
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        getFileName(str) {
            let tmp = str.split(";");
            let name = "";
            if (tmp[1]) {
                tmp = tmp[1].split("=");
                name = tmp[1];
            }
            return name;
        },
    },
};
</script>
