<template>
    <div class="root allH_100">
        <Header></Header>
        <div class="ding_24 root-content" style="background: #f0f2f5">
            <router-view v-slot="{ Component, route }">
                <transition name="fade-transform" mode="out-in">
                    <keep-alive :include="cacheRouteList">
                        <component :is="Component" :key="route.meta.usePathKey ? route.path : undefined" />
                    </keep-alive>
                </transition>
            </router-view>
            <div class="task_prompt" v-if="task.show" @click="$store.state.task.stop = true">
                <div class="task_prompt_icon">
                    <svg-icon icon="task" class="task_prompt_icon_content"></svg-icon>
                    <p>计算任务</p>
                </div>
                <div class="task_prompt_right">
                    <el-icon
                        class="task_prompt_right_close"
                        @click.stop="
                            (task.show = false),
                                $store.commit(`taskData`, {}),
                                $store.commit(`task`, {}),
                                this.timer && clearInterval(this.timer)
                        "
                    >
                        <CircleCloseFilled />
                    </el-icon>
                    <div class="task_prompt_right_number">
                        <span>{{
                            $store.state.taskData.status == 2
                                ? "已完成"
                                : $store.state.taskData.status == 3
                                ? "错误"
                                : $store.state.taskData.status == 1
                                ? "正在执行中"
                                : "数据准备中"
                        }}</span>
                        <span>共1个</span>
                    </div>
                    <div class="marg_top_2">
                        <el-link
                            type="primary"
                            @click="$store.commit(`task`, { stop: true, ...this.$store.state.task })"
                        >
                            点击查看处理任务
                        </el-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <mx-feedback v-model="feedback"></mx-feedback>
</template>

<script>
import { createNoticeModal } from "@/utils/assets.js";
import { bindChannelEvent } from "@/utils/utils.js";
import { queryById } from "@/api/comm.js";
import MxFeedback from "@/components/private/feedback/index.js";
import Header from "@/components/header.vue";
import Aside from "@/components/aside.vue";

export default {
    name: "home",
    components: { Aside: Aside, Header, MxFeedback },
    data() {
        return { feedback: false, task: { data: null, show: false, status: null } };
    },
    mounted() {
        this.bindBroadcastChannel();
    },
    methods: {
        bindBroadcastChannel() {
            bindChannelEvent(data => {
                if (data.type === "logout") {
                    this.$router.push("/login");
                }
            });
        },
        queryById(id) {
            queryById({ id: id }).then(res => {
                if (res.code === "000000") {
                    let arr = res.data.payload ? res.data.payload.split("_") : [];
                    if (res.data.status === 1) {
                        !this.task.data
                            ? createNoticeModal({ batchNum: arr[1], totalCount: arr[0] }, res.data.name)
                            : null;
                        this.task.show = false;
                        this.task.data = { batchNum: arr[1], totalCount: arr[0] };
                    } else if (res.data.status === 2) {
                        this.$store.commit(`taskData`, {});
                        3;
                        clearInterval(this.timer);
                        this.task.data = null;
                        this.task.show = true;
                    } else if (res.data.status === 3) {
                        clearInterval(this.timer);
                        this.task.show = true;
                    } else {
                        this.task.data = null;
                        this.task.show = true;
                    }
                    this.$store.commit(`taskData`, { ...res.data, batchNum: arr[1], totalCount: arr[0] });
                } else {
                    this.$store.commit(`taskData`, {});
                    this.task.data = null;
                    this.task.show = false;
                    this.$message.error(res.message);
                }
            });
        },
        showFeedback() {
            this.feedback = true;
        },
    },
    computed: {
        path() {
            return this.$route.fullPath;
        },
        cacheRouteList() {
            return this.$store.state.cacheList;
        },
    },
    watch: {
        "$store.state.task": {
            handler: function (val) {
                if (!val.stop && val.id) {
                    this.queryById(val.id);
                    let taskData = this.$store.state.taskData;
                    this.timer = setInterval(
                        () => {
                            setTimeout(this.queryById(val.id), 0);
                        },
                        taskData && taskData.totalCount
                            ? taskData.totalCount > 100000
                                ? 10000
                                : taskData.totalCount > 50000
                                ? 5000
                                : taskData.totalCount > 10000
                                ? 2000
                                : 1500
                            : 1500
                    );
                } else if (!val.id) {
                    this.$store.commit(`taskData`, {});
                    clearInterval(this.timer);
                } else if (val.stop == true) {
                    this.task.show = false;
                    clearInterval(this.timer);
                }
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>
<style lang="scss" scoped>
.root {
    background-color: rgb(240, 242, 245);
    padding: 0 0 24px 0;
    &-content {
        max-height: calc(100% - 80px);
        min-height: calc(100% - 300px);
        overflow-y: auto;
    }
}
</style>
<style lang="scss">
.task {
    &_prompt {
        position: absolute;
        z-index: 9999;
        bottom: 0;
        right: 0;
        width: 378.33px;
        height: 76.25px;
        border-radius: 5.61px;
        opacity: 1;

        /* 自动布局 */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4.68px;
        gap: 4.678107261657715px;

        background: #ffffff;

        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
        &_icon {
            color: #1d7ef6;
            text-align: center;
            width: 80px;
            &_content {
                font-size: 36px;
            }
            p {
                margin: 4px 0 0 0;
            }
        }
        &_right {
            width: calc(100% - 100px);
            position: relative;
            &_close {
                position: absolute !important;
                top: -12px;
                right: -12px;
                z-index: 9999;
            }
            &_number {
                display: flex;
                justify-content: space-between;
                color: #868b91;
            }
        }
    }
}
</style>
