export default [
    {
        path: "/income-expenditure/register",
        name: "incomeRegister",
        component: () => import("@/views/income-expenditure/register.vue"),
        meta: { title: "收支登记" },
    },
    {
        path: "/income-expenditure/costType",
        name: "incomeCostType",
        component: () => import("@/views/income-expenditure/costType.vue"),
        meta: { title: "费用类型维护" },
    },
    {
        path: "/income-expenditure/summary",
        name: "incomeSummary",
        component: () => import("@/views/income-expenditure/summary.vue"),
        meta: { title: "收支汇总" },
    },
];
