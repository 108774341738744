import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import directives from "./directives/index";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhCn from "element-plus/es/locale/lang/zh-cn";

import MxIcon from "./components/icon";
import SvgIcon from "./components/svg-icon";
import PageContainer from "./components/page-container";
import PageHeader from "./components/page-header";
import xlsImport from "./components/import";
import xlsExport from "./components/export";
import axiosExport from "./components/export-axios";
import fetchExport from "./components/fetch-export";
import MxTable from "./components/table";
import mxSelect from "./components/select";
import elSelectV2Custom from "./components/el-select-v2-custom";
import foxPreviewImage from "fox-preview-image";
import viewImgVideo from "./components/view-img-video";
import "fox-preview-image/lib/style.css";

import "./assets/css/public.scss";
const icons = require.context("./assets/icons", true, /\.svg$/);
const requireAll = requireContext => requireContext.keys().map(requireContext);
requireAll(icons);

import * as ElementPlusIconsVue from "@element-plus/icons-vue";

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}

app.provide("appVersion", "2.0.0");
app.use(store);
app.use(router);
app.use(MxIcon);
app.use(SvgIcon);
app.use(MxTable);
app.use(mxSelect);
app.use(xlsImport);
app.use(xlsExport);
app.use(PageHeader);
app.use(directives);
app.use(axiosExport);
app.use(fetchExport);
app.use(viewImgVideo);
app.use(PageContainer);
app.use(foxPreviewImage);
app.use(elSelectV2Custom);
app.use(ElementPlus, { locale: zhCn });
app.mount("#app");
