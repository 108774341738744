export default [
    {
        name: "financeBeginning",
        path: "/finance/set-up/beginning",
        component: () => import(/* webpackChunkName: "financeDashboard" */ "@/views/finance/set-up/beginning.vue"),
        meta: { title: "期初设置" },
    },
    {
        name: "financeSubject",
        path: "/finance/set-up/subject",
        component: () => import(/* webpackChunkName: "financeDashboard" */ "@/views/finance/set-up/subject.vue"),
        meta: { title: "科目设置" },
    },
    {
        name: "financeAccountCover",
        path: "/finance/set-up/account-cover",
        component: () =>
            import(/* webpackChunkName: "financeDashboard" */ "@/views/finance/set-up/account-cover/index.vue"),
        meta: { title: "账套设置" },
    },
    {
        name: "financeAccountCoverAdd",
        path: "/finance/set-up/account-cover/add",
        component: () =>
            import(/* webpackChunkName: "financeDashboard" */ "@/views/finance/set-up/account-cover/add.vue"),
        meta: { title: "账套新增" },
    },
    {
        name: "financeAccountCoverEdit",
        path: "/finance/set-up/account-cover/edit",
        component: () =>
            import(/* webpackChunkName: "financeDashboard" */ "@/views/finance/set-up/account-cover/edit.vue"),
        meta: { title: "账套编辑" },
    },
    {
        name: "financeSetUpCatalog",
        path: "/finance/set-up/catalog",
        component: () => import(/* webpackChunkName: "financeDashboard" */ "@/views/finance/set-up/catalog.vue"),
        meta: { title: "辅助核算" },
    },
    {
        name: "financeCheckOut", //结账页面
        path: "/finance/check/out",
        component: () => import(/* webpackChunkName: "financeDashboard" */ "@/views/finance/account/check_out.vue"),
        meta: { title: "结账" },
    },
];
