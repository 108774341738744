import { get, post, baseURL } from "@/utils/request.js";

/** 新增或编辑-辅助核算类别 / get, post, baseURL
 * @param {*} data
 */
export const update = data => {
    const url = "/finance/catalog/addOrUpdate";
    return post(url, data);
};

/** 查询-辅助核算类别
 * @param {*} data
 */
export const list = data => {
    const url = "/finance/catalog/queryAll";
    return get(url, data);
};

/** 删除-辅助核算类别
 * @param {*} data
 */
export const del = data => {
    const url = "/finance/catalog/delete";
    return get(url, data);
};

/** 查询一个-辅助核算类别
 * @param {*} data
 */
export const one = data => {
    const url = "/finance/catalog/queryOne";
    return get(url, data);
};
/** 查询-辅助核算类别条目
 * @param {*} data
 */
export const listQuery = data => {
    const url = "/finance/catalog/queryCatalogList";
    return get(url, data);
};
/** 查询-辅助核算类别条目
 * @param {*} data
 */
export const listQueryDetail = data => {
    const url = "/finance/catalogDetail/catalogRelateVoucher";
    return get(url, data);
};
/** 新增或编辑-辅助核算类别条目
 * @param {*} data
 */
export const catalogUpdate = data => {
    const url = "/finance/catalogDetail/addOrUpdate";
    return post(url, data);
};

/** 查询-辅助核算类别条目
 * @param {*} data
 */
export const catalogList = data => {
    const url = "/finance/catalogDetail/queryAll";
    return get(url, data);
};

/** 删除-辅助核算类别条目
 * @param {*} data
 */
export const catalogDel = data => {
    const url = "/finance/catalogDetail/delete";
    return get(url, data);
};

/** 自动获取code-辅助核算类别条目
 * @param {*} data
 */
export const catalogCode = data => {
    const url = "/finance/catalogDetail/generateCdCode";
    return get(url, data);
};

/** 批量x删除-辅助核算类别条目
 * @param {*} data
 */
export const catalogDelList = data => {
    const url = "/finance/catalogDetail/batchDelete";
    return get(url, data);
};

/** 导入 */
export const importUrl = `${baseURL}/finance/catalogDetail/import`;

/** 导出 */
export const exportUrl = `${baseURL}/finance/catalogDetail/export`;

/** 导入模板 */
export const templateUrl = `${baseURL}/finance/catalogDetail/getTemplateFile`;

//                              账套                              //

/** 新增或检查-账套 / get, post, baseURL
 * @param {*} data
 */
export const bookUpdate = data => {
    const url = "/finance/bookset/addOrUpdate";
    return post(url, data);
};

/** 检查-账套
 * @param {*} data
 */
export const checkBook = data => {
    const url = "/finance/bookset/checkBookset";
    return get(url, data);
};

/** 删除-账套
 * @param {*} data
 */
export const bookDel = data => {
    const url = "/finance/bookset/delete";
    return get(url, data);
};

/** 列表-账套
 * @param {*} data
 */
export const bookList = data => {
    const url = "/finance/bookset/queryAll";
    return get(url, data);
};

/** 切换-账套
 * @param {*} data
 */
export const switchUsing = data => {
    const url = "/finance/bookset/switchUsing";
    return get(url, data);
};

/** 详情-账套
 * @param {*} data
 */
export const getBookDefault = data => {
    const url = "/finance/bookset/queryOne";
    return get(url, data);
};

//                              期初                              //
/** 期初余额管理-列表
 * @par  am {*} data
 */
export const beginningPeriodList = data => {
    const url = "/finance/openingBalance/query";
    return get(url, data);
};

/** 导入模板 */
export const beginningTemplate = `${baseURL}/finance/openingBalance/exportTemplate`;

/** 导入模板 */
export const openingExport = `${baseURL}/finance/openingBalance/exportData`;

//                              科目                              //
/** 科目-导入模板
 * @par  am {*} data
 */
export const titleTemplate = data => {
    const url = "/finance/title/exportTemplate";
    return get(url, data, { responseType: "blob" });
};

/** 科目-导出
 * @par  am {*} data
 */
export const titleExport = `${baseURL}/finance/title/export`;

/** 科目-导入
 * @par  am {*} data
 */
export const titleImport = `${baseURL}/finance/title/importData`;

/** 新增明细
 * @param {*} data
 */
export const addAuxiliaryOpening = data => {
    const url = "/finance/openingBalance/addAuxiliaryOpening";
    return post(url, data);
};
/** 删除期初设置
 * @param {*} data
 */
export const deleteAuxiliaryOpening = data => {
    const url = "/finance/openingBalance/deleteAuxiliaryOpening";
    return get(url, data);
};

/** 禁用启用-科目设置
 * @param {*} data
 */
export const batchUpdate = data => {
    const url = `/finance/title/batchUpdate/${data.type}`;
    return post(url, data.ids);
};

/** 新增或编辑-科目设置
 * @param {*} data
 */
export const batchUpdate1 = data => {
    const url = `/finance/title/batchSetCatalog/${data.catalogId}`;
    return post(url, data.ids);
};
