import { createStore } from "vuex";

// modules
import state from "./state.js";
import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";
import plugins from "./plugins.js";

export default createStore({
    state,
    mutations,
    actions,
    getters,
    plugins,
    modules: {},
});
