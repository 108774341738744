<template>
    <el-dialog v-model="show">
        <feedback v-model="show"></feedback>
    </el-dialog>
</template>

<script>
import Feedback from "./feedback.vue";
export default {
    name: "MxFeedback",
    components: { Feedback },
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            show: false,
        };
    },
    watch: {
        modelValue: {
            handler(val) {
                this.show = val;
            },
            immediate: true,
        },
        show(val) {
            this.$emit("update:modelValue", !!val);
        },
    },
};
</script>
