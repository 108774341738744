// 插件
import storage from "@/utils/storage.js";
import createPersistedState from "vuex-persistedstate";

export default [
    createPersistedState({
        key: "ztoVuex",
        storage: { setItem: storage.set, getItem: storage.get, removeItem: storage.remove },
        paths: [
            "isFirstLogin",
            "menus",
            "menusIn",
            "userInfo",
            "token",
            "modules",
            "pathList",
            "cacheList",
            "pageTabs",
            "novice",
            "task",
            "taskData",
        ],
    }),
];
