/**
 *
 * @param el
 * @param binding
 * v-input.money 限制输入金额
 * v-input.int 限制输入整数
 * 可用于 input 或 el-input等输入框组件
 */

const vInput = (el, binding) => {
    let inp;
    if (el.tagName.toLowerCase() == "input") {
        inp = el;
    } else {
        inp = el.querySelector("input");
    }
    // 金额限制
    function limitMoney(value) {
        return value.replace(/[^-?\d.]/g, "").replace(/^(-?\d+)(.\d{1,2}$)$/g, "$1$2");
        // .replace(/[^-?\d.]/g, "")
        // .replace(/\.{2,}/g, ".")
        // .replace(/^0+/g, "0")
        // .replace(/^0+\./g, "0.")
        // .replace(/^0+\d/g, "0")
    }
    // 整数限制
    function limitInteger(value) {
        return value.replace(/[^\d]/g, "").replace(/^0+/g, "0").replace(/^0+\d/g, "0");
    }
    inp.addEventListener("input", () => {
        // 金额限制
        if (binding.modifiers.money) return (inp.value = limitMoney(inp.value.toString()));
        // 整数限制
        if (binding.modifiers.int) return (inp.value = limitInteger(inp.value.toString()));
    });
};
export default vInput;
