export default [
    {
        path: "/salary/index",
        name: "salaryList",
        component: () => import("@/views/salary/index.vue"),
        meta: { title: "薪资管理" },
    },
    {
        path: "/salary/summary",
        name: "salarySummary",
        component: () => import("@/views/salary/summary.vue"),
        meta: { title: "薪资汇总" },
    },
];
