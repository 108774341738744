export default [
    {
        name: "billComparison",
        path: "/bill/send/comparison/customer",
        component: () => import(/* webpackChunkName: "billComparison" */ "@/views/bill/send/comparison/customer.vue"),
        meta: {
            title: "客户账单对比",
            target: "_blank",
        },
    },
    {
        name: "importBillComparison",
        path: "/bill/send/comparison/import",
        component: () => import(/* webpackChunkName: "billComparison" */ "@/views/bill/send/comparison/import.vue"),
        meta: {
            title: "导入账单对比",
            target: "_blank",
        },
    },
];
