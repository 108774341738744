export default {
    userInfo: state => {
        return state.userInfo;
    },
    dotList: state => {
        return state.dotList;
    },
    costType: state => {
        return state.costType;
    },
    routerList: state => {
        return state.routerList;
    },
    menus: state => {
        return state.menus;
    },
    menusIn: state => {
        return state.menusIn;
    },
    pageTabs(state, value) {
        state.pageTabs = value;
    },
    cacheList(state, value) {
        state.cacheList = value;
    },
    defaultRoute: state => {
        return state.defaultRoute;
    },
    tableLoading: state => {
        return state.tableLoading;
    },
    modules: state => {
        return state.modules;
    },
    downloadIconBig: state => {
        return state.downloadIconBig;
    },
    finance: state => {
        return state.finance;
    },
    task: state => {
        return state.task;
    },
    taskData: state => {
        return state.taskData;
    },
};
