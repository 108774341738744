export default [
    {
        name: "accountList",
        path: "/bill/send/settlement/account",
        component: () => import(/* webpackChunkName: "settlement" */ "@/views/bill/send/settlement/account.vue"),
        meta: { title: "账户管理" },
    },
    {
        name: "settlementList",
        path: "/bill/send/settlement/list",
        component: () => import(/* webpackChunkName: "settlement" */ "@/views/bill/send/settlement/list.vue"),
        meta: { title: "结算明细" },
    },
    {
        name: "settlementSummary",
        path: "/bill/send/settlement/summary",
        component: () => import(/* webpackChunkName: "settlement" */ "@/views/bill/send/settlement/summary.vue"),
        meta: { title: "结算汇总" },
    },
];
