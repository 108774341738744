export default [
    {
        path: "/financial-management/accounts-receivable",
        name: "dotFinanceAccountsReceivable",
        component: () => import("@/views/financial-management/accounts-receivable/index.vue"),
        meta: { title: "应收账款管理" },
    },
    {
        path: "/financial-management/accounts-receivable/log",
        name: "dotFinanceAccountsReceivableLog",
        component: () => import("@/views/financial-management/accounts-receivable/log.vue"),
        meta: { title: "应收账款日志" },
    },
    {
        path: "/financial-management/accounts-receivable/advanceList",
        name: "advanceList",
        component: () => import("@/views/financial-management/accounts-receivable/advanceList.vue"),
        meta: { title: "账款预付" },
    },
    {
        path: "/financial-management/analysis",
        name: "financialManagementAnalysis",
        component: () => import("@/views/financial-management/analysis/index.vue"),
        meta: { title: "回款分析" },
    },
];
