import { get, post } from "@/utils/request.js";

export const agency = data => {
    const url = "/charging/homePage/pending";
    return get(url, data);
};

/** 消息列表
 * @param {*} data
 */
export const noticeList = data => {
    const url = "/charging/sysNotice/findHomeAnnouncement";
    return post(url, data);
};

/** 获取海报
 */
export const activityList = data => {
    const url = "/charging/postmanage/multipleConditions";
    return post(url, data);
};

export const isFirstLogin = dotId => {
    const url = `/charging/dotBusinessInformation/check?dotId=${dotId}`;
    return get(url);
};

export const searchMenu = data => {
    const url = `/charging/cfgMenu/searchMenu`;
    return get(url, data);
};
export const userUpdCollect = data => {
    const url = `/charging/user/userUpdCollect`;
    return get(url, data);
};
