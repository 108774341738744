<template>
    <el-button
        v-if="!text"
        :type="type"
        :size="size"
        :disabled="disabled"
        :plain="plain ? true : false"
        @click="change"
    >
        <slot>导出</slot>
    </el-button>
    <el-text
        v-else
        :type="type == 'default' ? '' : type"
        :size="size"
        :disabled="disabled"
        :plain="plain ? true : false"
        @click="change"
    >
        <slot>导出</slot>
    </el-text>
</template>

<script>
export default {
    name: "xlsExport",
    props: {
        text: { type: Boolean, default: false },
        action: { type: String, default: "javascript:void;" },
        accept: { type: String, default: "" },
        type: { type: String, default: "default" },
        name: String,
        plain: { type: Boolean, default: false },
        data: { type: Object, default: () => {} },
        disabled: Boolean,
        size: { type: String, default: "default" },
        callback: Function,
    },
    methods: {
        change() {
            const state = this.$store.state;
            const url = this.action;
            const { admin, dotId, stationId } = state.userInfo;
            const RANDOM = window.btoa(`${admin}&${dotId}&${stationId}`);
            const token = { "site-assistance-token": state.token, RANDOM: RANDOM };
            const data = Object.assign(Object.create(null), this.data, token) || token;
            window.open(url + "?" + this.json2param(data), "_black");
            if (this.callback) {
                this.callback();
            }
        },
        json2param(json) {
            let arr = [];
            for (let i in json) {
                if (["", null, undefined].includes(json[i])) {
                    json[i] = "";
                }
                arr.push(`${i}=${json[i]}`);
            }
            let str = arr.join("&");
            return str;
        },
    },
};
</script>
