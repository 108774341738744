export default {
    isFirstLogin: false,
    userInfo: {},
    menus: [],
    menusIn: {},
    novice: null,
    pageTabs: [],
    cacheList: [],
    tableLoading: false,
    pathList: [],
    token: "",
    timer: null,
    toastCount: 1,
    notices: new Map(),
    websocketInstance: null,
    modules: "",
    downloadIconBig: false,
    finance: {},
    task: {},
    taskData: {},
};
