export default [
    {
        name: "financeSurfaceProfitList",
        path: "/finance/surface/profit-list",
        component: () => import(/* webpackChunkName: "manualRecord" */ "@/views/finance/surface/profit-list.vue"),
        meta: { title: "利润表" },
    },
    {
        name: "financeSurfaceCashList",
        path: "/finance/surface/cash-list",
        component: () => import(/* webpackChunkName: "manualRecord" */ "@/views/finance/surface/cash-list.vue"),
        meta: { title: "现金流量表" },
    },
    {
        name: "financeSurfaceBalanceSheet",
        path: "/finance/surface/balance-sheet",
        component: () => import(/* webpackChunkName: "manualRecord" */ "@/views/finance/surface/balance-sheet.vue"),
        meta: { title: "资产负债表" },
    },
    {
        name: "financeSurfaceOperating-profit",
        path: "/finance/surface/operating-profit",
        component: () => import(/* webpackChunkName: "manualRecord" */ "@/views/finance/surface/operating-profit.vue"),
        meta: { title: "网点经营报表" },
    },
    {
        name: "financeAccountDetail",
        path: "/finance/account/detail",
        component: () => import(/* webpackChunkName: "manualRecord" */ "@/views/finance/account/detail.vue"),
        meta: { title: "明细账" },
    },
    {
        name: "financeAccountAuxiliaryDetail",
        path: "/finance/account/auxiliary-detail",
        component: () => import(/* webpackChunkName: "manualRecord" */ "@/views/finance/account/auxiliary-detail.vue"),
        meta: { title: "辅助核算明细账" },
    },
    {
        name: "financeAccountBalance",
        path: "/finance/account/balance",
        component: () => import(/* webpackChunkName: "manualRecord" */ "@/views/finance/account/balance.vue"),
        meta: { title: "科目余额" },
    },
];
