export default [
    {
        path: "/claims/claims-in/claimsList",
        name: "claimsIn",
        component: () => import("@/views/claims-in/claimsList.vue"),
        meta: { title: "进港理赔管理" },
    },
    {
        path: "/claims/claims-out/claimsList",
        name: "claimsOut",
        component: () => import("@/views/claims-out/claimsList.vue"),
        meta: { title: "出港理赔管理" },
    },
    {
        path: "/claims/claims-private/index",
        name: "claimsPrivate",
        component: () => import("@/views/claims-private/index.vue"),
        meta: { title: "理赔私了" },
    },
    {
        path: "/claims/claims-onLine/claimsList",
        name: "claimsOnLine",
        component: () => import("@/views/claims-onLine/claimsList.vue"),
        meta: { title: "线上理赔" },
    },
    {
        path: "/claims/claims-onLineSummary/claimsList",
        name: "claimsOnLineSummary",
        component: () => import("@/views/claims-onLine-summary/claimsList.vue"),
        meta: { title: "理赔汇总" },
    },
];
