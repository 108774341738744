export default [
    {
        name: "financeInvoice",
        path: "/finance/invoice",
        component: () => import(/* webpackChunkName: "financeDashboard" */ "@/views/finance/invoice/index.vue"),
        meta: {
            title: "发票管理",
        },
    },
    {
        name: "financeInvoiceAdd",
        path: "/finance/invoice/add",
        component: () => import(/* webpackChunkName: "financeDashboard" */ "@/views/finance/invoice/add.vue"),
        meta: { requireAuth: false, title: "新增发票" },
    },
    {
        name: "financeInvoiceEdit",
        path: "/finance/invoice/edit",
        component: () => import(/* webpackChunkName: "financeDashboard" */ "@/views/finance/invoice/edit.vue"),
        meta: { requireAuth: false, title: "编辑发票" },
    },
];
