import DataManagement from "./data-management.js";
import quote from "./quote.js";
import send from "./send/index.js";
import salesmanSettlement from "./salesman-settlement.js";

const finances = [
    ...DataManagement,
    // ...reportForm,
    ...quote,
    ...send,
    // ...reimbursement,
    // ...settlement,
    ...salesmanSettlement,
];

export default finances;
