const materiel = [
    {
        name: "faceSheetManagement",
        path: "/materiel/faceSheetManagement",
        component: () => import(/* webpackChunkName: "rebate" */ "@/views/materiel/faceSheetManagement/index.vue"),
        meta: { title: "面单管理" },
    },
    {
        name: "faceSheetManagementDetail",
        path: "/materiel/faceSheetManagement/detail",
        component: () => import(/* webpackChunkName: "rebate" */ "@/views/materiel/faceSheetManagement/detail.vue"),
        meta: { title: "面单使用明细" },
    },
    {
        name: "inventoryManage",
        path: "/materiel/inventoryManage",
        component: () => import(/* webpackChunkName: "rebate" */ "@/views/materiel/inventoryManage/index.vue"),
        meta: { title: "库存查询" },
    },
    {
        name: "inventoryManageFluctuation",
        path: "/materiel/inventoryManage/fluctuation",
        component: () => import(/* webpackChunkName: "rebate" */ "@/views/materiel/inventoryManage/fluctuation.vue"),
        meta: { title: "库存变动" },
    },
    {
        name: "materielDistribute",
        path: "/materiel/distribute",
        component: () => import(/* webpackChunkName: "rebate" */ "@/views/materiel/distribute/index.vue"),
        meta: { title: "物料发放" },
    },
    {
        name: "materielDistributeDetail",
        path: "/materiel/distribute/detail",
        component: () => import(/* webpackChunkName: "rebate" */ "@/views/materiel/distribute/detail.vue"),
        meta: { title: "物料发放明细" },
    },
];

export default materiel;
