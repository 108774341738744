export default [
    {
        name: "importBillComparisonContract",
        path: "/bill/report/importBillComparisonContract",
        component: () =>
            import(/* webpackChunkName: "billComparison" */ "@/views/bill/send/import-bill-comparison-contract.vue"),
        meta: { title: "导入账单对比(承包区)", target: "_blank" },
    },

    {
        name: "paymentMonitoring",
        path: "/bill/management/paymentMonitoring",
        component: () =>
            import(/* webpackChunkName: "paymentMonitoring" */ "@/views/bill/send/management/payment-monitoring.vue"),
        meta: { title: "应收回款监控" },
    },
    {
        name: "paymentReceivedSum",
        path: "/bill/management/paymentMonitoring/sum",
        component: () =>
            import(
                /* webpackChunkName: "paymentMonitoring" */ "@/views/bill/send/management/payment-monitoring-sum.vue"
            ),
        meta: { title: "应收回款汇总" },
    },
    {
        name: "uncollectedPaymentSumUncollected",
        path: "/bill/management/paymentMonitoring/sum/uncollected",
        component: () =>
            import(
                /* webpackChunkName: "paymentMonitoring" */ "@/views/bill/send/management/payment-monitoring-sum-uncollected.vue"
            ),
        meta: { title: "应收未回款汇总" },
    },
    {
        name: "paymentMonitoringUser",
        path: "/bill/management/paymentMonitoring/user",
        component: () =>
            import(
                /* webpackChunkName: "paymentMonitoring" */ "@/views/bill/send/management/payment-monitoring-user.vue"
            ),
        meta: { title: "应收回款监控(承包区)" },
    },
    {
        name: "paymentReceivedUserSum",
        path: "/bill/management/paymentMonitoring/user/sum",
        component: () =>
            import(
                /* webpackChunkName: "paymentMonitoring" */ "@/views/bill/send/management/payment-monitoring-user-sum.vue"
            ),
        meta: { title: "应收回款汇总(承包区)" },
    },
    {
        name: "uncollectedPaymentUserSumUncollected",
        path: "/bill/management/paymentMonitoring/user/sum/uncollected",
        component: () =>
            import(
                /* webpackChunkName: "paymentMonitoring" */ "@/views/bill/send/management/payment-monitoring-user-sum-uncollected.vue"
            ),
        meta: { title: "应收未回款汇总(承包区)" },
    },
    {
        name: "paymentMonitoringRule",
        path: "/bill/management/paymentMonitoring/rule",
        component: () =>
            import(
                /* webpackChunkName: "paymentMonitoring" */ "@/views/bill/send/management/payment-monitoring-rule.vue"
            ),
        meta: { title: "周期和预警设置" },
    },
    {
        name: "paymentMonitoringRuleUser",
        path: "/bill/management/paymentMonitoring/rule/user",
        component: () =>
            import(
                /* webpackChunkName: "paymentMonitoring" */ "@/views/bill/send/management/payment-monitoring-rule-user.vue"
            ),
        meta: { title: "周期和预警设置(承包区)" },
    },
];

// {
//         name: "capitalFlow",
//         path: "/bill/capital/flow",
//         component: () => import(/* webpackChunkName: "capital" */ "@/views/bill/capital/flow.vue"),
//         meta: {
//             title: "资金流水",
//         },
//     },
//     {
//         name: "capitalBalance",
//         path: "/bill/capital/balance",
//         component: () => import(/* webpackChunkName: "capital" */ "@/views/bill/capital/balance.vue"),
//         meta: {
//             title: "账户余额",
//         },
//     },
//     {
//         name: "capitalSum",
//         path: "/bill/capital/sum",
//         component: () => import(/* webpackChunkName: "capital" */ "@/views/bill/capital/sum.vue"),
//         meta: {
//             title: "资金汇总",
//         },
//     },
//     {
//         name: "capitalSubject",
//         path: "/bill/capital/subject",
//         component: () => import(/* webpackChunkName: "capital" */ "@/views/bill/capital/subject.vue"),
//         meta: {
//             title: "科目维护",
//         },
//     },
