import { post, get } from "@/utils/request.js";

/**
 * 登陆接口--账号密码登陆
 * @param {*} data
 */
export const login = data => {
    const url = "/loginByBlockPuzzle";
    return post(url, data);
};

/**
 * 登陆接口--验证码登陆
 * @param {phone: String, msgCode: String} data
 */
export const loginByCode = data => {
    const url = "/charging/sendAndLogin/login";
    return get(url, data);
};

/**
 * 登出接口
 * @param {*} data
 */
export const logout = data => {
    const url = "/logout";
    return post(url, data);
};
/**
 * 登出接口
 * @param {*} data
 */
export const tryOut = data => {
    const url = "/tryOut";
    return post(url, data);
};

/**
 * 获取验证码--手机号
 * @param {phone: String} data
 */
export const getCodeByTel = data => {
    const url = "/charging/sendAndLogin/smsverification";
    return get(url, data);
};

/**
 * 获取验证码--图片
 * @param {phone: String} data
 */
export const getImgCode = data => {
    const url = "/getcode";
    return get(url, data);
};
/**
 * 切换网点--图片
 * @param {phone: String} data
 */
export const getDotId = data => {
    const url = "/login/related/getWxBlindDotList";
    return get(url, data);
};
/**
 * 切换网点--图片
 * @param {phone: String} data
 */
export const getDotSwi = data => {
    const url = "/login/related/switchWxBlindDot";
    return get(url, data);
};
export const wxLogin = data => {
    const url = "/wx/wxChoseLogin";
    return get(url, data);
};
