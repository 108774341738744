import { post, baseURL } from "@/utils/request";

export const taskSyncList = data => {
    const url = "/sync/tsk/lst";
    return post(url, data);
};

export const taskSse = sn => {
    return `${baseURL}/sync/sse/conn?sn=${sn}`;
};

export const retryTask = data => {
    const url = "/sync/tsk/retry";
    return post(url, data);
};
