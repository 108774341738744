<template>
    <div class="flex flex-row-between flex-col-center padding-row-large margin-bottom" :style="$attrs.style">
        <div>
            <h2 class="h2">{{ title }}</h2>
        </div>
        <div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "PageHeader",
    props: {
        title: String,
        offset: {
            type: Number,
            default: 0,
        },
        fixed: Boolean,
    },
};
</script>

<style scoped>
.h2 {
    font-size: 18px;
    font-weight: 600;
}
.is-fixed {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: #fff;
    padding: 12px 24px;
}
</style>
