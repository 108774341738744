export default [
    {
        name: "customer",
        path: "/basic/customer",
        component: () =>
            import(/* webpackChunkName: "customer" */ "@/views/basic/customer-management/customer/customer.vue"),
        meta: {
            title: "客户管理",
        },
    },
    {
        name: "customerAdd",
        path: "/basic/customer/add",
        component: () =>
            import(/* webpackChunkName: "customer" */ "@/views/basic/customer-management/customer/add.vue"),
        meta: {
            title: "客户管理新增",
        },
    },
    {
        name: "customerEdit",
        path: "/basic/customer/edit",
        component: () =>
            import(/* webpackChunkName: "customer" */ "@/views/basic/customer-management/customer/edit.vue"),
        meta: {
            title: "客户管理修改",
        },
    },
    {
        name: "customerDetail",
        path: "/basic/customer/detail",
        component: () =>
            import(/* webpackChunkName: "customer" */ "@/views/basic/customer-management/customer/detail.vue"),
        meta: {
            title: "客户管理详情",
        },
    },
    {
        name: "merchant",
        path: "/basic/merchant",
        component: () =>
            import(/* webpackChunkName: "merchants" */ "@/views/basic/customer-management/merchant/merchant.vue"),
        meta: {
            title: "商户管理",
        },
    },
    {
        name: "merchantAdd",
        path: "/basic/merchant/add",
        component: () =>
            import(/* webpackChunkName: "merchants" */ "@/views/basic/customer-management/merchant/add.vue"),
        meta: {
            title: "商户管理新增",
        },
    },
    {
        name: "merchantEdit",
        path: "/basic/merchant/edit",
        component: () =>
            import(/* webpackChunkName: "merchants" */ "@/views/basic/customer-management/merchant/edit.vue"),
        meta: {
            title: "商户管理修改",
        },
    },
    {
        name: "merchantDetail",
        path: "/basic/merchant/detail",
        component: () =>
            import(/* webpackChunkName: "merchants" */ "@/views/basic/customer-management/merchant/detail.vue"),
        meta: {
            title: "商户管理详情",
        },
    },
];
