<template>
    <div class="sa-page-tabs">
        <div class="sa-page-tabs-body">
            <div class="sa-page-tabs-home">
                <div
                    class="sa-page-tabs-item-text hover_409eff d_flex cursor_pointer"
                    @click="$router.push({ path: '/dashboard' })"
                    :class="{ 'sa-page-tabs-item-active': cur == '/dashboard' }"
                >
                    <el-icon><HomeFilled /></el-icon> 首页
                </div>
            </div>
            <div class="sa-page-tabs-item-content" ref="tabBody">
                <div class="sa-page-tabs-wrap" :style="{ transform: `translate(${x}px)` }">
                    <template v-for="item in data" :key="item.name">
                        <div ref="tabWrap" class="sa-page-tabs-item cursor_pointer" @click.stop="change(item)">
                            <div
                                class="sa-page-tabs-item-text hover_409eff"
                                :class="{ 'sa-page-tabs-item-active': cur === item.path }"
                                :title="item.title && item.title.length > 6 ? item.title : null"
                            >
                                <span class="text_overflow_1">{{ item.title }}</span>
                                <el-icon @click.stop="close(item.name)" class="marg_left_2"><CloseBold /></el-icon>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <el-dropdown max-height="240px" v-if="dropdownShowW" @command="change" :disabled="!dropdownShowW">
            <el-icon class="marg_right_12" :style="!dropdownShowW ? 'color:#fff' : ''"><ArrowDownBold /></el-icon>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item
                        v-for="(item, index) in data"
                        :key="index"
                        :disabled="cur === item.path"
                        :command="item"
                        >{{ item.title }}</el-dropdown-item
                    >
                </el-dropdown-menu>
            </template>
        </el-dropdown>
        <img src="@/assets/images/menu/icon_xc.png" width="16" class="cursor_pointer" @click="clearList" />
    </div>
</template>

<script>
import { HomeFilled, CloseBold, ArrowDownBold } from "@element-plus/icons-vue";
export default {
    name: "MxPageTabs",
    components: { HomeFilled, CloseBold, ArrowDownBold },
    props: {
        data: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            cur: null,
            x: 0,
            dropdownShowW: false,
        };
    },
    methods: {
        init(val) {
            if (val) return;
            if (this.$refs.tabBody) {
                this.$nextTick(() => {
                    let wrapWidth = Math.ceil(
                        Number(
                            getComputedStyle(this.$refs.tabBody)["width"].substring(
                                0,
                                getComputedStyle(this.$refs.tabBody)["width"].length - 2
                            )
                        )
                    );
                    let arrInd = this.data.filter((v, i) => {
                        v.ind = i;
                        return this.cur === v.path;
                    });
                    if (arrInd.length) {
                        let arr = this.$refs.tabWrap.map(v =>
                            Math.ceil(
                                Number(
                                    getComputedStyle(v)["width"].substring(0, getComputedStyle(v)["width"].length - 2)
                                )
                            )
                        );
                        if (arrInd[0].ind === this.data[this.data.length - 1].ind) {
                            let maxMoveX = wrapWidth - eval(arr.filter((v, i) => i <= arrInd[0].ind).join("+"));
                            if (Math.abs(this.x) > maxMoveX) {
                                this.x = maxMoveX < 0 ? maxMoveX : 0;
                            } else {
                                this.x = 0;
                            }
                        } else {
                            let arr1 = arr.filter((v, i) => i <= arrInd[0].ind + 1);
                            let maxMoveX = wrapWidth - eval(arr1.join("+"));
                            if (Math.abs(this.x) > maxMoveX) {
                                this.x = maxMoveX < 0 ? maxMoveX : 0;
                            } else {
                                this.x = 0;
                            }
                        }
                    }
                });
            }
            this.init(1);
        },
        status(val) {
            return this.cur === val ? "warning" : "#cccccc";
        },
        close(name) {
            let tabs = this.$store.state.pageTabs || [];
            let routes = this.$store.state.cacheList || [];
            let tab = null;
            let index = 0;
            tabs = tabs.filter((v, i) => {
                if (v.name === name) {
                    tab = v;
                    index = i;
                    return false;
                } else {
                    return true;
                }
            });
            routes = routes.filter(v => {
                return v !== tab.name;
            });
            const len = tabs.length;
            if (len === 0) {
                this.$store.commit(`cacheList`, routes);
                this.$store.commit(`pageTabs`, tabs);
                this.$router.push({ path: "/dashboard" });
                return;
            } else {
                this.$store.commit(`cacheList`, routes);
                this.$store.commit(`pageTabs`, tabs);
            }
            if (index === len) {
                this.$router.push({ path: tabs[len - 1].path, query: tabs[len - 1].query });
            } else if (
                tabs.filter(v => v.path == this.$route.path)
                    ? !tabs.filter(v => v.path == this.$route.path).length
                    : null
            ) {
                this.$router.push({ path: tabs[index].path, query: tabs[index].query });
            }
        },
        clearList() {
            if (this.$route.path !== "/dashboard") {
                let arr = this.$store.state.pageTabs.filter(v => v.path == this.$route.path);
                this.$store.commit(
                    `cacheList`,
                    this.$store.state.cacheList.filter(v => v == arr[0].name)
                );
                this.$store.commit(`pageTabs`, arr);
            } else {
                this.$router.push({ path: "/dashboard" });
                this.$store.commit(`cacheList`, []);
                this.$store.commit(`pageTabs`, []);
            }
        },
        change(e) {
            const name = e.name;
            const cur = this.$route.name;
            if (cur !== name) {
                let tabs = this.$store.state.pageTabs || [];
                const curPageTab = tabs.filter(v => v.name === name)[0];
                this.$router.push({ path: curPageTab.path, query: curPageTab.query });
            }
        },
    },
    watch: {
        $route: {
            handler(val) {
                if (val.path == "/dashboard") {
                    this.cur = "/dashboard";
                } else {
                    this.cur = val.path;
                }
                this.init();
            },
            deep: true,
            immediate: true,
        },
        data: {
            handler() {
                this.$nextTick(() => {
                    this.init();
                    if (this.$refs.tabWrap && this.$refs.tabWrap.length) {
                        let wrapWidth = Math.ceil(
                            Number(
                                getComputedStyle(this.$refs.tabBody)["width"].substring(
                                    0,
                                    getComputedStyle(this.$refs.tabBody)["width"].length - 2
                                )
                            )
                        );
                        let arr = this.$refs.tabWrap.map(v =>
                            Math.ceil(
                                Number(
                                    getComputedStyle(v)["width"].substring(0, getComputedStyle(v)["width"].length - 2)
                                )
                            )
                        );
                        if (eval(arr.join("+")) > wrapWidth) {
                            this.dropdownShowW = true;
                        } else {
                            this.dropdownShowW = false;
                        }
                    }
                });
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>

<style lang="scss">
.sa-page-tabs {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-body {
        flex: auto;
        max-width: 100%;
        overflow: hidden;
        display: flex;
    }
    &-wrap {
        display: flex;
        transition: transform 0.3s;
    }
    &-home {
        display: flex;
        height: 34px;
        width: 76px;
        align-items: center;
        box-sizing: border-box;
        line-height: 1em;
        border-right: 1px solid #e5e6e8;
    }
    &-item {
        height: 34px;
        padding: 0 8px;
        line-height: 1em;
        border-right: 1px solid #e5e6e8;
        &-text {
            height: 100%;
            display: flex;
            border-radius: 16px;
            align-items: center;
            justify-content: center;
            padding: 6px 10px;
            font-size: 14px;
            font-weight: 400;
            color: #4e5969;
            span {
                max-width: 120px;
            }
        }
        &-content {
            width: 100%;
            overflow: hidden;
        }
        &-active {
            background-color: #f7f8fa;
            border-radius: 16px;
            color: #409eff;
        }
    }

    &-item:last-child {
        border: none;
    }
}
.icon-show {
    width: 24px !important;
    height: 24px !important;
    border-radius: 50%;
    background-color: #409efc;
}
</style>
