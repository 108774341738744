<template>
    <el-dialog v-model="visible" class="wx_class" :modal="false" :show-close="false" width="364px">
        <div class="text_center wx_class_content">
            <p class="text_center font_size_18">微信扫一扫，绑定账号</p>
            <img src="@/assets/images/dashboard/wx.png" class="wxImg" />
            <div class="wxLogin">
                <iframe
                    sandbox="allow-scripts allow-top-navigation allow-same-origin"
                    scrolling="no"
                    width="300"
                    height="300"
                    frameBorder="0"
                    allowTransparency="true"
                    :src="wxData.urlSrc"
                ></iframe>
            </div>
            <p class="text_center font_size_12">绑定微信后，下次可用微信扫码快捷登录</p>
        </div>
    </el-dialog>
</template>

<script>
import { wxBlind } from "@/api/wx/wx.js";

export default {
    name: "dashBoard",
    data() {
        return {
            wxData: { somaSwitch: 500, appid: null, redirect_uri: null, scope: null, state: null, self_redirect: null },
            visible: false,
        };
    },
    props: {
        modelValue: { type: Boolean, default: false },
        config: {
            type: Object,
            default() {
                return { code: "", pwd: "888888" };
            },
        },
    },
    methods: {
        wxDataShow() {
            wxBlind().then(res => {
                var href =
                    "data:text/css;base64,Ci5pbXBvd2VyQm94IC50aXRsZSB7CiAgICBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94IC5zdGF0dXMuc3RhdHVzX2Jyb3dzZXIgewogICAgZGlzcGxheTogbm9uZTsKfQouaW1wb3dlckJveCAubG9naW5QYW5lbCB7CiAgICBkaXNwbGF5OiBmbGV4OwogICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7CiAgICBhbGlnbi1pdGVtczogY2VudGVyOwp9Ci5pbXBvd2VyQm94ICAud2FpdGluZyB7CiAgICB3aWR0aDogMTAwJTsKICAgIHRleHQtYWxpZ246IGNlbnRlcjsKfQogCi5pbXBvd2VyQm94ICAud3JwX2NvZGUgeyAKICAgIHdpZHRoOiAxMDAlOwogICAgcGFkZGluZzogMnB4IDdweCAwcHggNHB4Owp9Ci5pbXBvd2VyQm94IC5zdGF0dXMgewogICAgZGlzcGxheTogbm9uZTsKfQ==";
                this.wxData.urlSrc =
                    res.data.replace("STATE#wechat_redirect", "") + `&theme=black&self_redirect=default&href=${href}`;
            });
        },
        getSift(list, name, num) {
            let arr = list.filter(v => {
                return !v.indexOf(name);
            })[0];
            return arr.slice(num);
        },
    },
    computed: {
        userInfo: {
            get() {
                return this.$store.state.userInfo;
            },
        },
    },
    watch: {
        modelValue: {
            handler(val) {
                this.visible = val;
                if (val) {
                    this.wxDataShow();
                }
            },
            immediate: true,
        },
        visible: {
            handler(val) {
                this.$emit("update:modelValue", !!val);
            },
            immediate: true,
        },
    },
};
</script>
<style lang="scss">
.wx {
    &_class {
        .el-dialog__header {
            padding: 0;
            display: none;
        }
        .wxImg {
            width: 54px;
            left: calc(50% - 30px);
            border: 1px solid #fff;
            top: -28px;
            border-radius: 50%;
            background-color: #fff;
            position: absolute;
        }
    }
}
</style>
