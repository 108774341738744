import input from "./vInput";

// 注意：使用时需要(v-)开头
const directives = { input };

export default {
    install(app) {
        Object.keys(directives).forEach(key => {
            app.directive(key, directives[key]);
        });
    },
};
