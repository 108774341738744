const indeividualCenter = [
    {
        name: "recharge",
        path: "/mine/recharge",
        component: () => import(/* webpackChunkName: "recharge" */ "@/views/self-center/recharge.vue"),
        meta: { title: "转入" },
    },
    {
        name: "pay",
        path: "/mine/pay",
        component: () => import(/* webpackChunkName: "recharge" */ "@/views/self-center/pay.vue"),
        meta: { title: "支付" },
    },
    {
        name: "transferAccounts",
        path: "/mine/transfer-accounts",
        component: () => import(/* webpackChunkName: "recharge" */ "@/views/self-center/transfer-accounts.vue"),
        meta: { title: "对公转账" },
    },
    {
        name: "individualLog",
        path: "/individualCenter/log",
        component: () => import(/* webpackChunkName: "log" */ "@/views/self-center/log.vue"),
        meta: { title: "用户日志" },
    },
];

export default indeividualCenter;
