<template>
    <el-button
        v-if="!text"
        :size="size"
        :type="type"
        :plain="plain ? true : false"
        :loading="loading"
        @click="chooseFile"
    >
        <slot>导入</slot>
    </el-button>
    <el-text
        v-else
        :size="size"
        :type="type == 'default' ? '' : type"
        :plain="plain ? true : false"
        :loading="loading"
        @click="chooseFile"
    >
        <slot>导入</slot>
    </el-text>
</template>
<script>
import axios from "axios";
export default {
    name: "xlsImport",
    props: {
        action: { type: String, default: "" },
        text: { type: Boolean, default: false },
        accept: { type: String, default: "" },
        name: { type: String, default: "file" },
        type: { type: String, default: "default" },
        plain: { type: Boolean, default: false },
        size: { type: String, default: "default" },
        data: { type: Object },
        fileSize: { type: Number, default: 100 },
        step: { type: String, default: "1" },
    },
    data() {
        return {
            currentValue: null,
            loading: false,
            importModal: false,
            excelAll: [],
            excelShow: [],
            page: { pageNow: 1, pageTotal: 0, pageSize: 10 },
        };
    },
    methods: {
        chooseFile() {
            let inputEle = document.createElement("input");
            inputEle.type = "file";
            inputEle.accept = this.accept;
            inputEle.onchange = this.change;
            inputEle.click();
        },
        change(ev) {
            const vm = this;
            vm.loading = true;
            let data = new FormData();
            const files = ev.target.files[0];
            const size = files.size / 1024 / 1024;
            if (size > vm.fileSize) {
                vm.loading = false;
                vm.$emit("onExceed", ...[files, `${vm.fileSize}MB`]);
                return false;
            }
            data.set(vm.name, files, files.name);
            if (vm.data !== undefined) {
                for (let i in vm.data) {
                    data.set(i, vm.data[i]);
                }
            }
            vm.$emit("onChange", ...[files, data]);

            vm.uploadFile(data, ev);
        },
        uploadFile(data, ev) {
            //提交文件至后台插库
            var vm = this;
            const url = vm.action;
            const state = this.$store.state;
            const { admin, dotId, stationId } = state.userInfo;
            const RANDOM = window.btoa(`${admin}&${dotId}&${stationId}`);

            axios
                .post(url, data, {
                    contentType: false,
                    timeout: 0,
                    headers: { "site-assistance-token": state.token, RANDOM: RANDOM },
                })
                .then(res => {
                    if (res.status == 200) {
                        if (res.data.code == "000000") {
                            vm.$emit("onSuccess", res.data);
                        } else {
                            vm.$emit("onError", res.data);
                        }
                    } else {
                        vm.$emit("onError", res);
                    }
                    vm.loading = false;
                    ev.path[0].value = "";
                })
                .catch(err => {
                    vm.loading = false;
                    ev.path ? (ev.path[0].value = "") : null;
                    throw new Error(err);
                });
        },
    },
};
</script>

<style scoped></style>
