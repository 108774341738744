import { get, post } from "@/utils/request";

export const wxBlind = data => {
    const url = "/wx/blind";
    return get(url, data);
};

export const wxBlindConfirm = data => {
    const url = "/wx/blindConfirm";
    return get(url, data);
};

export const wxLogin = data => {
    const url = "/wx/login";
    return get(url, data);
};

export const wxLoginCallBack = data => {
    const url = "/wx/loginCallBack";
    return get(url, data);
};
export const wxDeleteWxBlind = data => {
    const url = `/wx/deleteWxBlind?userId=${data}`;
    return post(url);
};
