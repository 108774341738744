<template>
    <el-dialog :title="titleName" v-model="visible" width="560px" @close="closeEdit">
        <el-form
            class="marg_top_16"
            :model="formForgot"
            :rules="rulesForgot"
            ref="loginFormForgot"
            v-if="userInfo.contactInformation"
            label-width="100px"
        >
            <el-form-item prop="phone" label="手机号">
                <el-input
                    v-model="formForgot.phone"
                    class="background_C_F2"
                    disabled
                    placeholder="请输入手机号码"
                    maxlength="11"
                />
            </el-form-item>
            <el-form-item prop="code" label="验证码">
                <el-input v-model="formForgot.code" class="background_C_F2 allW_100" placeholder="请输入验证码" />
                <el-button type="primary" size="small" class="code-button" plain @click="getCode">
                    {{ btnText }}</el-button
                >
            </el-form-item>
            <el-form-item prop="pwd" label="修改密码">
                <el-input v-model="formForgot.pwd" class="background_C_F2" placeholder="请输入密码" />
            </el-form-item>
            <el-form-item prop="checkPwd" label="确认密码">
                <el-input v-model="formForgot.checkPwd" class="background_C_F2" placeholder="请再次输入密码" />
            </el-form-item>
        </el-form>
        <div class="text_center" v-else>
            <el-icon color="#FF4D4F" size="124px"><WarningFilled /></el-icon>
            <p style="font-size: 16px; font-weight: 400; color: #1d2129; line-height: 32px">
                该账号未维护手机号，请联系管理员维护。
            </p>
        </div>
        <template #footer v-if="userInfo.contactInformation">
            <el-button @click="visible = false">取消</el-button>
            <el-button type="primary" @click="submitForm">确定</el-button>
        </template>
    </el-dialog>
</template>
<script>
import { getCodeByTel, resetPassword1 } from "@/api/forgot-password.js";
import { WarningFilled } from "@element-plus/icons-vue";
import { sendChannelMessage } from "@/utils/utils.js";
import { getUsers } from "@/api/basic/user/user.js";
import { bookList } from "@/api/finance/set-up.js";
import { searchMenu } from "@/api/dashboard.js";

export default {
    data() {
        const phone = (rule, value, callback) => {
            if (value) {
                const reg = /^1(([38]\d)|(4[5-9])|(5[^4])|(6[56])|(7[0-8])|(9[189]))\d{8}$/;
                if (reg.test(value)) {
                    callback();
                } else {
                    callback(new Error("请输入真实的手机号码"));
                }
            } else {
                callback(new Error("请输入手机号码"));
            }
        };
        const phoneCode = (rule, value, callback) => {
            if (value) {
                const reg = /^[\d]{6}$/;
                if (reg.test(value)) {
                    callback();
                } else {
                    callback(new Error("验证码为6位数字"));
                }
            } else {
                callback(new Error("请输入验证码"));
            }
        };
        const cpPwd = (rule, value, callback) => {
            // const reg = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{8,20}$/;
            // const reg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
            let num = 0;
            if (/[A-Z]/.test(value)) {
                num++;
            }
            if (/[a-z]/.test(value)) {
                num++;
            }
            if (/\d/.test(value)) {
                num++;
            }
            var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
            if (pattern.test(value)) {
                num++;
            }

            if (value) {
                if (num >= 3 && value.length >= 8 && value.length <= 20) {
                    callback();
                } else {
                    callback(new Error("密码为8-20位,必须包括大写字母、小写字母、数字、特殊字符4种中的3种"));
                }
            } else {
                callback(new Error("密码不能为空"));
            }
        };
        const checkPwd = (rule, value, callback) => {
            if (value) {
                if (value === this.formForgot.pwd) {
                    callback();
                } else {
                    callback(new Error("两次输入的密码不一致"));
                }
            } else {
                callback(new Error("请输入密码"));
            }
        };
        return {
            formForgot: { phone: "", code: "", pwd: "", checkPwd: "" },
            rulesForgot: {
                phone: [{ required: true, validator: phone, trigger: "change" }],
                code: [{ required: true, validator: phoneCode, trigger: "change" }],
                pwd: [{ required: true, validator: cpPwd, trigger: "change" }],
                checkPwd: [{ required: true, validator: checkPwd, trigger: "change", pwd: this.formItem }],
            },
            visible: false,
            titleName: "登录密码修改",
            btnText: "获取验证码",
        };
    },
    components: { WarningFilled },
    props: ["modelValue"],
    emits: ["sure"],
    methods: {
        closeEdit() {
            this.$refs["loginFormForgot"] ? this.$refs["loginFormForgot"].resetFields() : null;
        },
        getCode() {
            this.$refs.loginFormForgot.validateField(["phone"], valid => {
                if (valid) {
                    const data = { phone: this.formForgot.phone };
                    if (this.btnText !== "获取验证码") {
                        return false;
                    }
                    getCodeByTel(data).then(res => {
                        if (res.code === "000000") {
                            this.$message.success(res.message);
                            this.countdown();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                }
            });
        },
        countdown() {
            let s = 120;
            this.btnText = "120s";
            let timer = setInterval(() => {
                if (s > 0) {
                    s--;
                    this.btnText = `${s}s`;
                } else {
                    this.btnText = "获取验证码";
                    clearInterval(timer);
                }
            }, 1000);
        },
        //修改密码(保存)
        submitForm() {
            this.$refs["loginFormForgot"].validate(valid => {
                if (valid) {
                    const data = {
                        phone: this.formForgot.phone,
                        verifyCode: this.formForgot.code,
                        newName: this.formForgot.pwd,
                        confirmName: this.formForgot.checkPwd,
                    };
                    resetPassword1(data).then(res => {
                        if (res.code === "000000") {
                            this.$emit("sure", { show: true, data: res });
                            this.closeEdit();
                            this.resetStoreState();
                            this.closeChannelEvent();
                            let resData = res.data;
                            this.$store.commit("token", resData.loginToken);
                            searchMenu().then(res => {
                                if (res.code === "000000") {
                                    let arr = [...res.data.filter(v => v.url && v.hasPermission)];
                                    bookList().then(res => {
                                        if (res.code === "000000") {
                                            let arr1 = res.data.list.map(v => ({
                                                id: v.id,
                                                url: "/finance/set-up/account-cover",
                                                name: "账套:" + v.compName,
                                            }));
                                            this.$store.commit("pathList", [...arr, ...arr1]);
                                        } else {
                                            let arr = [];
                                            this.$store.commit("pathList", arr);
                                        }
                                    });
                                } else {
                                    let arr = [];
                                    this.$store.commit("pathList", arr);
                                }
                            });
                            getUsers({
                                limit: 1,
                                offset: 1,
                                item: {
                                    name: resData.name,
                                    status: "",
                                    remark1: "",
                                    remark2: "",
                                    code: "",
                                    dotId: resData.dotId,
                                },
                            }).then(res => {
                                if (res.code == "000000") {
                                    resData = { ...resData, code: res.data.list[0].code };
                                }
                                this.$store.commit("userInfo", resData || {});
                                this.$store.dispatch("getAuth", true);
                            });
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                }
            });
        },

        resetStoreState() {
            this.$store.commit(`pageTabs`, []);
            this.$store.commit(`cacheList`, []);
        },
        closeChannelEvent() {
            sendChannelMessage("zto-main", { type: "close" });
            sendChannelMessage("zto-module", { type: "close" });
        },
    },
    computed: {
        userInfo: function () {
            return this.$store.getters.userInfo;
        },
        loginDisableByPhone() {
            const flag = this.loginByPhone.disabled.phone && this.loginByPhone.disabled.code;
            return !flag;
        },
    },
    watch: {
        modelValue: {
            handler(val) {
                this.visible = val;
                this.formForgot.phone = Number(this.$store.getters.userInfo.contactInformation);
            },
            immediate: true,
        },
        visible(val) {
            this.$emit("update:modelValue", !!val);
        },
    },
};
</script>

<style lang="scss" scoped>
// .editPassword {
//     background-color: #fff;
// }
.code {
    &-button {
        position: absolute;
        right: 3%;
    }
}
.background_C_F2 {
    :deep(.el-input__wrapper) {
        padding: 0;
    }
    :deep(.el-input__inner) {
        padding: 1px 11px;
        background-color: #f2f3f5;
    }
}
</style>
