export default [
    {
        name: "staff",
        path: "/basic/staff",
        sensitive: true,
        component: () => import(/* webpackChunkName: "staff" */ "@/views/basic/staff-management/staff/list.vue"),
        meta: { title: "员工管理" },
    },
    {
        name: "staffAdd",
        path: "/basic/staff/add",
        component: () => import(/* webpackChunkName: "staff" */ "@/views/basic/staff-management/staff/add.vue"),
        meta: { title: "员工管理新增" },
    },
    {
        name: "staffEdit",
        path: "/basic/staff/edit",
        component: () => import(/* webpackChunkName: "staff" */ "@/views/basic/staff-management/staff/edit.vue"),
        meta: { title: "员工管理修改" },
    },
    {
        name: "staffDetail",
        path: "/basic/staff/detail",
        component: () => import(/* webpackChunkName: "staff" */ "@/views/basic/staff-management/staff/detail.vue"),
        meta: { title: "员工管理详情" },
    },

    {
        name: "role",
        path: "/basic/station",
        component: () => import(/* webpackChunkName: "station" */ "@/views/basic/staff-management/station/station.vue"),
        meta: { title: "岗位管理" },
    },
    {
        name: "stationAdd",
        path: "/basic/station/add",
        component: () => import(/* webpackChunkName: "station" */ "@/views/basic/staff-management/station/add.vue"),
        meta: { title: "岗位管理新增" },
    },
    {
        name: "stationEdit",
        path: "/basic/station/edit",
        component: () => import(/* webpackChunkName: "station" */ "@/views/basic/staff-management/station/edit.vue"),
        meta: { title: "岗位管理修改" },
    },
    {
        name: "auth",
        path: "/basic/station/auth",
        component: () => import(/* webpackChunkName: "station" */ "@/views/basic/staff-management/station/auth.vue"),
        meta: { title: "权限管理" },
    },
    {
        name: "department",
        path: "/basic/department",
        component: () =>
            import(/* webpackChunkName: "department" */ "@/views/basic/staff-management/department/department.vue"),
        meta: { title: "部门管理" },
    },
    {
        name: "departmentAdd",
        path: "/basic/department/add",
        component: () =>
            import(/* webpackChunkName: "department" */ "@/views/basic/staff-management/department/add.vue"),
        meta: { title: "部门管理新增" },
    },
    {
        name: "departmentEdit",
        path: "/basic/department/edit",
        component: () =>
            import(/* webpackChunkName: "department" */ "@/views/basic/staff-management/department/edit.vue"),
        meta: { title: "部门管理修改" },
    },
];
