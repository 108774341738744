export default [
    {
        name: "dataInterfaceBind",
        path: "/basic/business-setting/data-interface-bind",
        component: () =>
            import(/* webpackChunkName: "business-setting" */ "@/views/basic/business-setting/data-interface-bind.vue"),
        meta: { title: "数据接口绑定" },
    },
    {
        name: "expenseType",
        path: "/basic/business-setting/expense-type",
        component: () =>
            import(/* webpackChunkName: "business-setting" */ "@/views/basic/business-setting/expense-type/index.vue"),
        meta: { title: "费用类型维护" },
    },
    {
        name: "settlementObject",
        path: "/basic/business-setting/settlement-object",
        component: () =>
            import(/* webpackChunkName: "business-setting" */ "@/views/basic/business-setting/settlement-object.vue"),
        meta: { title: "结算对象绑定" },
    },
    {
        name: "businessSettingOther",
        path: "/basic/business-setting/other",
        component: () => import(/* webpackChunkName: "business-setting" */ "@/views/basic/business-setting/other.vue"),
        meta: { title: "其它设置" },
    },
    {
        name: "productName",
        path: "/basic/business-setting/product-name",
        component: () =>
            import(/* webpackChunkName: "business-setting" */ "@/views/basic/business-setting/product-name.vue"),
        meta: { title: "物料品名维护" },
    },
];
