export default {
    token(state, val) {
        state.token = val;
    },
    setToastCount(state, val) {
        state.toastCount = val;
    },
    userInfo: function (state, data) {
        state.userInfo = data;
    },
    loginOut: function (state) {
        state.userInfo = { isRead: 1 };
        state.token = "";
        state.modules = "";
        state.task = {};
        state.menusIn = {};
        state.menus = [];
    },
    pathList(state, value) {
        state.pathList = value;
    },
    routes(state, value) {
        state.routes = value;
    },
    menus(state, value) {
        state.menus = value;
    },
    menusIn(state, value) {
        state.menusIn = value;
    },
    setCostType(state, value) {
        state.costType = value;
    },
    defaultRoute(state, value) {
        state.defaultRoute = value || "";
    },
    tableLoading(state, val) {
        state.tableLoading = val || false;
    },
    setTimer(state, val) {
        state.timer = val;
    },
    pageTabs(state, value) {
        state.pageTabs = value;
    },
    task(state, value) {
        state.task = value;
    },
    cacheList(state, value) {
        state.cacheList = value;
    },
    addNotices(state, value) {
        state.notices.set(value.key, value.value);
    },
    removeNotice(state, key) {
        state.notices.delete(key);
    },
    websocketInstance(state, value) {
        state.websocketInstance = value;
    },
    modules(state, val) {
        state.modules = val;
    },
    isFirstLogin(state, val) {
        state.isFirstLogin = val;
    },
    novice(state, val) {
        state.novice = val;
    },
    downloadIconBig(state, val) {
        state.downloadIconBig = val;
    },
    taskData(state, val) {
        state.taskData = val;
    },
};
