export default [
    {
        name: "settlementDetails",
        path: "/reconciliationHQ/settlementDetails",
        component: () =>
            import(/* webpackChunkName: "gross-profit" */ "@/views/reconciliationHQ/settlementDetails/index.vue"),
        meta: { title: "总部结算明细", target: "_blank" },
    },
    {
        name: "shippingCost",
        path: "/reconciliationHQ/shippingCost",
        component: () =>
            import(/* webpackChunkName: "gross-profit" */ "@/views/reconciliationHQ/shippingCost/index.vue"),
        meta: { title: "总部发件成本", target: "_blank" },
    },
    {
        name: "settlementHQSummary",
        path: "/reconciliationHQ/settlementSummary",
        component: () =>
            import(/* webpackChunkName: "gross-profit" */ "@/views/reconciliationHQ/settlementSummary/index.vue"),
        meta: { title: "总部结算汇总", target: "_blank" },
    },
    {
        name: "reconciliationDelivery",
        path: "/reconciliationHQ/delivery",
        component: () => import(/* webpackChunkName: "gross-profit" */ "@/views/reconciliationHQ/delivery/index.vue"),
        meta: { title: "总部派件综合", target: "_blank" },
    },
];
