<template>
    <svg class="n-icon" aria-hidden="true" :style="iconSize" v-bind="$attrs">
        <use :xlink:href="iconName"></use>
    </svg>
</template>

<script>
import { computed } from "vue";

export default {
    name: "SvgIcon",
    props: {
        icon: { type: String, required: true },
        size: [String, Number],
        color: String,
    },
    setup(props, { attrs }) {
        const iconSize = computed(() => {
            if (props.size) {
                return { ...attrs.style, fontSize: `${Number(props.size)}px` };
            } else {
                return { ...attrs.style };
            }
        });
        const iconName = computed(() => {
            return `#n-icon-${props.icon}`;
        });
        return { iconSize, iconName };
    },
};
</script>

<style scoped>
.n-icon {
    display: inline-flex;
    line-height: 1em;
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}
</style>
