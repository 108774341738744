export default [
    {
        name: "orderPlace",
        path: "/renew/orderPlace",
        component: () => import("@/views/renew/orderPlace.vue"),
        meta: { title: "选择套餐" },
    },
    {
        name: "orderPayment",
        path: "/renew/orderPayment/:amount/:orderCode/:id",
        component: () => import("@/views/renew/orderPayment.vue"),
        meta: { title: "套餐支付" },
    },
    {
        name: "payList",
        path: "/renew/payList",
        component: () => import("@/views/renew/payList.vue"),
        meta: { title: "支付列表" },
    },
];
