import { post, get, baseURL } from "@/utils/request.js";

export const websocketUrl = userId => {
    if (baseURL.includes("https")) {
        return `${baseURL}/websocket/open/${userId}_${new Date().getTime()}`.replace("https:", "wss:");
    } else {
        return `${baseURL}/websocket/open/${userId}_${new Date().getTime()}`.replace("http:", "ws:");
    }
};

/**
 * 获取菜单
 * @param {Object} data
 */
export const getAuth = data => {
    const url = "/loginRids";
    return get(url, data);
};
/**
 * 获取省市列表
 * @param {*} data
 */
export const provinceAndCity = data => {
    const url = "/charging/destinationGroup/getDestinationGroup";
    return post(url, data);
};
/**
 * 获取省份列表
 * @param {*} data
 */
export const province = data => {
    const url = "/charging/destinationGroup/getDestinationGroupProvince";
    return post(url, data);
};
/**
 * 获取城市列表
 * @param {*} data
 */
export const getDestinationGroupCity = data => {
    const url = "/charging/destinationGroup/getDestinationGroupCity";
    return post(url, data);
};
/**
 * 得到客户结款类型
 * @param {*} data
 */
export const getPayType = (data = null) => {
    const url = "/charging/bpaymenttype/getBPaymentType";
    return post(url, data);
};

/**
 * 得到客户费用类型《不分页》
 * @param {*} data
 */
export const getCostType = (data = null) => {
    const url = "/charging/costType/getBCostTypeList";
    return post(url, data);
};

/**
 * 费用类型
 * @param {*} data
 */
export const costTypeDrop = (data = null) => {
    const url = "/charging/costType/getBCostTypeBySort";
    return get(url, data);
};

/**
 * 下拉列表--商家名称
 * @param {} data
 */
export const merchantsDrop = data => {
    const url = "/charging/merchant/selectName";
    return post(url, data);
};
/**
 * 下拉列表 客户列表
 * @param {*} data
 */
export const customerDrop = data => {
    const url = "/charging/tMaterialMainten/getTMaterialMaintenCus";
    return post(url, data);
};
/**
 * 下拉列表 客户列表 筛选 新增时判断员工账户的一级账户是否开户且为启用状态
 * @param {*} data
 */
export const findNameList = data => {
    const url = "/charging/balanceAccount/findNameList";
    return post(url, data);
};
/**
 * 下拉列表 承包人列表
 * @param {*} data
 */
export const contractDrop = data => {
    const url = "/charging/tMaterialMainten/getTMaterialMaintenUser";
    return get(url, data);
};
/**
 * 下拉列表 承包人列表--新版承包区
 * @param {*} data
 */
/* export const contractDrop =  (data) => {
    const url = '/charging/ContractArea/selectContractAreaList';
    return post(url, data);
} */
/**
 * 下拉列表 员工列表--根据网点查询
 * @param {*} data
 */
export const userListDrop = data => {
    const url = "/charging/user/getTUserList";
    return post(url, data);
};
/**
 * 下拉列表 业务员列表（员工表）
 * @param {*} data
 */
export const userDrop = data => {
    const url = "/charging/user/getTUserDotId";
    return post(url, data);
};
/**
 * 下拉列表 运输方式
 * @param {*} data
 */
export const transferDrop = data => {
    const url = "/charging/transportType/getBTransportTypeList";
    return post(url, data);
};
/**
 * 下拉列表 部门
 * @param {dotId: String} data dotId:网点id
 */
export const departmentDrop = data => {
    const url = "/charging/department/selectTDepartmentByDotId";
    return get(url, data);
};

/**
 * 下拉列表 岗位
 * @param {departmentId: String,dotId: String} data departmentId-部门id；dotId-网点id
 */
export const stationDrop = data => {
    const url = "/charging/tStation/selectTStationNameById";
    return post(url, data);
};

/**
 * 寄件组模板
 * @param {dotId: String/Number, type: Number} data type:0|1|2 --客户|承包区|全部
 */
export const templateSendGroupDrop = data => {
    const url = "/charging/bSendGroup/selectBSendGroupNameAndId";
    return post(url, data);
};
/**
 * 目的组模板
 * @param {dotId: String/Number} data dotId:网点id
 */
export const templateSendAreaDrop = data => {
    const url = "/charging/bDestinationGroupManager/selectBDestinationGroupManagerNameAndId";
    return get(url, data);
};

/**
 * 根据客户获取商户列表
 * @param {Object} data
 */
export const getMerchantListByCustomer = data => {
    const url = "/charging/incomeRegister/selectRelevanceMerchant";
    return post(url, data);
};

export const materialDrop = data => {
    const url = "charging/bill/materialList";
    return post(url, data);
};

export const getDotContract = data => {
    const url = "/charging/ContractArea/findByDotId";
    return get(url, data);
};
/**
 * 导入接口
 */
export const importUrl = `${baseURL}/import/dataManagement/import`;
/**
 * 下载模版接口
 */
export const downloadUrl = `${baseURL}/dataManagement/download`;
// 导入接口
export const importUrlOpening = `${baseURL}/charging/openingSettings/importData`;
/**
 * 通过品名和客户获取系统销售价格
 * @param {object} data
 */
export const getSaleMoneySys = data => {
    const url = "/charging/tMaterialMainten/getConditionMaterial";
    return post(url, data);
};

export const uploadAvatarUrl = `${baseURL}/charging/upload/uploadpictures`;

// 此项目新增的接口

/**手工账费用类型 */
export const expenseTypeOfManual = () => {
    const url = "/costTypeSetting/getZtoManualBillCost";
    return get(url);
};
/**
 * @name 发货窗口
 * */
export const shippingWindow = dotId => {
    const url = `/shipmentsWindow/getAll?dotId=${dotId}`;
    return get(url);
};

/**
 * 下拉列表  品名
 * @param {*} data
 */
export const productNameDrop = data => {
    const url = "/charging/quotations/getMaterialOfFaceSheet";
    return get(url, data);
};

export const getUnitList = () => {
    const url = "/charging/tUnitType/getTUnitTypeList";
    return post(url, null);
};
/**基础报价导入 */
export const simpleQuoteImport = data => {
    const url = "/charging/newQuotations/getImportQuotations";
    return post(url, data);
};
/**基础报价导出 */
export const simpleQuoteExport = data => {
    const url = "/charging/newQuotations/exportImportQuotations";
    return post(url, data, { responseType: "blob" });
};
/**查询结算一级对象列表 */
export const getSettlementObjectListOfTopLevel = () => {
    const url = "/charging/settlementPersonManagement/getFirstObject";
    return post(url);
};

/**模板新增 */
export const templateAdd = data => {
    const url = "/customTemplate/add";
    return post(url, data);
};
/**模板删除 */
export const templateDelete = data => {
    const url = "/customTemplate/delete";
    return get(url, data);
};
/**模板列表 */
export const templateList = data => {
    const url = "/customTemplate/findList";
    return post(url, data);
};
/**动态费用列表 */
export const feeFieldList = data => {
    const url = "costTypeSetting/getZeroAccountType";
    return get(url, data);
};
/**随即代码 */
export const randomCode = data => {
    const url = "/charging/tDot/selectDotUserAndCusCodeCount";
    return get(url, data);
};

// 上传接口
export const uploadApi = `${baseURL}/salesman/file/upload/pics`;

/**获取轮询数据 */
export const queryById = data => {
    const url = `/tTaskCenter/queryById`;
    return get(url, data);
};
/**计算列表 */
export const queryCalPage = data => {
    const url = "tTaskCenter/queryCalPage";
    return post(url, data);
};
// 取消计算
export const overTask = data => {
    const url = "tTaskCenter/overTask";
    return get(url, data);
};
/**导入列表 */
export const queryImportPage = data => {
    const url = "tTaskCenter/queryImportPage";
    return post(url, data);
};
