import { get, post } from "@/utils/request.js";

/**
 * 顶部未读消息列表
 * @param {*} data
 */
export const unreadMessageList = data => {
    const url = "/charging/sysNotice/selectUnreadMessages";
    return post(url, data);
};

/**
 * 消息列表
 * @param {*} data
 */
export const list = data => {
    const url = "/charging/sysNotice/selectNoticePage";
    return post(url, data);
};

/**
 * 批量删除
 * @param {*} data
 */
export const del = data => {
    const url = "/charging/sysNotice/deleteNoticeUpdate";
    return post(url, data);
};

/**
 * 标记为已读
 * @param {*} data
 */
export const readMessage = data => {
    const url = "/charging/sysNotice/updateNotice";
    return post(url, data);
};

/**
 * 标记为全部已读
 * @param {*} data
 */
export const allRead = data => {
    const url = "/charging/sysNotice/updateNoticeIsRead";
    return post(url, data);
};

/**
 * 下拉列表 消息分类列表
 * @param {} data departmentId-部门id；dotId-网点id
 */
export const messageTypeDrop = data => {
    const url = "/charging/sysNotice/selectNoticeType";
    return get(url, data);
};
