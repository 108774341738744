<template>
    <div v-click-outside="onClickOutside" ref="buttonRef">
        <el-input
            class="mx-select-input__inner"
            clearable
            v-model="curValue"
            :placeholder="placeholder"
            :disabled="disabled"
            @input="searchKey"
            @clear="closed"
            :size="size"
            v-bind="$attrs"
        />
    </div>

    <el-popover
        ref="elPopover"
        :virtual-ref="buttonRef"
        placement="bottom-start"
        virtual-triggering
        trigger="click"
        :width="getDropWidth"
        popper-class="mx-select"
    >
        <div class="mx-select__wrapper" :style="{ width: getDropWidth }">
            <div class="mx-select__wrapper-header" v-if="props.titleText2">
                <div>{{ props.titleText1 }}</div>
                <div>{{ props.titleText2 }}</div>
            </div>
            <div class="mx-select__wrapper-body">
                <div v-if="showList && showList.length">
                    <div
                        v-for="item in showList"
                        :key="item[props.props['value']]"
                        class="mx-select-option"
                        :class="[{ 'is-selected': props.modelValue === item[props.props.value] }]"
                        @click="optionClick(item)"
                    >
                        <div :style="getOptionItemWidth" :title="filterTitle(item[props.props['label']])">
                            {{ item[props.props["label"]] }}
                        </div>
                        <div v-if="titleText2" :title="filterTitle(item[props.props['value']], 2)">
                            {{ item[props.props["value"]] }}
                        </div>
                    </div>
                </div>
                <div v-else class="mx-select__empty">
                    <slot>{{ empty }}</slot>
                </div>
            </div>
            <div class="mx-select__wrapper-footer" v-show="props.hasPage">
                <el-pagination
                    v-model:currentPage="page.current"
                    @current-change="currentChange"
                    layout="total, prev, jumper, next"
                    :total="page.total"
                />
            </div>
        </div>
    </el-popover>
</template>

<script setup>
import { ref, unref, defineProps, computed, defineEmits, watchEffect, reactive } from "vue";
import { ClickOutside as vClickOutside } from "element-plus";
const buttonRef = ref();
const elPopover = ref();

// props
const props = defineProps({
    modelValue: { type: [String, Boolean, Number, Array] },
    disabled: { type: Boolean, default: false },
    data: [Array],
    filter: { type: Boolean, default: false },
    placeholder: { type: String, default: "请选择" },
    dropWidth: { type: [String, Number], default: 220 },
    width: { type: [String, Number], default: 220 },
    hasPage: { type: Boolean, default: false },
    props: {
        type: Object,
        default() {
            return { label: "name", value: "value" };
        },
    },
    titleText1: String,
    titleText2: String,
    titleValue1: String,
    titleValue2: String,
    empty: { type: String, default: "-数据为空-" },
    size: { type: String, default: "default" },
});

// emits

const emit = defineEmits(["change", "update:modelValue"]);

// data
const curValue = ref("");
const wrapWidth = ref(200);
const page = reactive({ current: 1, size: 8, total: 0, list: [] });
const showList = ref([]);

// methods

const onClickOutside = () => {
    unref(elPopover).popperRef?.delayHide?.();
};

const searchKey = () => {
    if (!props.filter) return false;
    let timer = null;
    if (timer !== null) {
        clearTimeout(timer);
        timer = null;
    }
    timer = setTimeout(() => {
        // 初始化page.list
        if (curValue.value) {
            page.list = props.data
                .filter(item => {
                    if (
                        item[props.props.label].includes(curValue.value) ||
                        item[props.props.value].toString().includes(curValue.value)
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                })
                .sort();
        } else {
            // 初始化selected
            page.list = props.data;
            page.list = page.list.map(item => {
                item.selected = false;
                return item;
            });
        }

        // 初始化showList
        if (props.hasPage) {
            page.total = page.list.length;
            showList.value = page.list.slice(0, page.size);
        } else {
            showList.value = page.list;
        }
        clearTimeout(timer);
        timer = null;
    }, 300);
};
const initData = () => {
    page.current = 1;
    page.list = props.data;
    // 初始化selected
    page.list.map(item => {
        if (item[props.props.value] === props.modelValue) {
            curValue.value = item[props.props.label];
        }

        return item;
    });
    // 初始化showList
    if (props.hasPage) {
        const start = page.size * (page.current - 1);
        const end = page.size * page.current;
        showList.value = page.list.slice(start, end);
        page.total = page.list.length;
    } else {
        showList.value = page.list;
    }
};
const initValue = () => {
    if ([undefined, null, ""].includes(props.modelValue)) {
        curValue.value = "";
    } else {
        props.data.map(item => {
            if (item[props.props.value] === props.modelValue) {
                curValue.value = item[props.props.label];
            }
            return item;
        });
    }
};
const currentChange = val => {
    // page.current = val;
    const start = page.size * (val - 1);
    const end = page.size * val;
    showList.value = page.list.slice(start, end);
};

const optionClick = val => {
    curValue.value = val[props.props.label];
    const value = val[props.props.value];
    emit("update:modelValue", value);
    emit("change", value, val);
    unref(elPopover).popperRef?.delayHide?.(100);
};

const clearData = () => {
    curValue.value = null;
    emit("update:modelValue", null, null);
    page.list = props.data.map(item => {
        item.selected = false;
        return item;
    });
    // 初始化showList
    if (props.hasPage) {
        page.total = page.list.length;
        showList.value = page.list.slice(0, page.size);
    } else {
        showList.value = page.list;
    }
};
const closed = () => {
    page.current = 1;
    clearData();
};
const filterTitle = (v, s = 1) => {
    let target = "";
    const dropWidth = props.dropWidth ? props.dropWidth : wrapWidth.value;
    const wid = dropWidth;
    const len = v.length;
    let tLen = len * 10;

    if (props.titleText2) {
        if (s == 1) {
            target = (wid - 26) * 0.55 - 10 < tLen ? v : "";
        } else {
            tLen = len * 8;
            target = (wid - 26) * 0.45 - 20 < tLen ? v : "";
        }
    } else {
        target = wid - 26 < tLen ? v : "";
    }
    return target;
};

const getDropWidth = computed(() => {
    if (props.dropWidth) {
        return `${props.dropWidth}px`;
    } else {
        return `${wrapWidth.value}px`;
    }
});
const getOptionItemWidth = computed(() => {
    if (!props.titleText2) {
        return { width: "100%", paddingRight: 0 };
    } else {
        return "";
    }
});

watchEffect(() => {
    initData();
    initValue();
});
</script>

<!-- <script setup>
import { ClickOutside } from "element-plus";
export default {
    name: "mxSelect",
    directives: {
        "v-click-outside": ClickOutside,
    },
    props: {
        modelValue: {
            type: [String, Boolean, Number, Array],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        data: [Array],
        filter: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "请选择",
        },
        dropWidth: {
            type: [String, Number],
            default: 220,
        },
        width: {
            type: [String, Number],
            default: 220,
        },
        hasPage: {
            type: Boolean,
            default: false,
        },
        props: {
            type: Object,
            default() {
                return {
                    label: "name",
                    value: "value",
                };
            },
        },
        titleText1: String,
        titleText2: String,
        titleValue1: String,
        titleValue2: String,
        empty: {
            type: String,
            default: "-数据为空-",
        },
        size: {
            type: String,
            default: "default",
        },
    },
    data() {
        return {
            curValue: "",
            visible: false,
            wrapWidth: this.dropWidth,
            page: {
                current: 1,
                size: 8,
                total: 0,
                list: [],
            },
            showList: [],
        };
    },

    methods: {
        inputFocus() {
            if (this.disabled) {
                return;
            }
            this.visible = true;
        },
        hideSelectWrap() {
            this.visible = false;
        },
        searchKey() {
            if (this.multiple || !this.filter) return false;
            let timer = null;
            if (timer !== null) {
                clearTimeout(timer);
                timer = null;
            }
            timer = setTimeout(() => {
                // 初始化page.list
                if (this.curValue) {
                    this.page.list = this.data
                        .filter(item => {
                            if (
                                item[this.props.label].includes(this.curValue) ||
                                item[this.props.value].toString().includes(this.curValue)
                            ) {
                                return true;
                            } else {
                                return false;
                            }
                        })
                        .sort();
                } else {
                    // 初始化selected
                    this.page.list = this.data;
                    this.page.list = this.page.list.map(item => {
                        item.selected = false;
                        return item;
                    });
                }

                // 初始化showList
                if (this.hasPage) {
                    this.page.total = this.page.list.length;
                    this.showList = this.page.list.slice(0, this.page.size);
                } else {
                    this.showList = this.page.list;
                }
                clearTimeout(timer);
                timer = null;
            }, 300);
        },
        initData() {
            this.page.current = 1;
            this.page.list = this.data;
            // 初始化selected
            this.page.list.map(item => {
                if (item[this.props.value] === this.modelValue) {
                    this.curValue = item[this.props.label];
                }

                return item;
            });
            // 初始化showList
            if (this.hasPage) {
                const start = this.page.size * (this.page.current - 1);
                const end = this.page.size * this.page.current;
                this.showList = this.page.list.slice(start, end);
                this.page.total = this.page.list.length;
            } else {
                this.showList = this.page.list;
            }
        },
        initValue() {
            if ([undefined, null, ""].includes(this.modelValue)) {
                this.curValue = "";
            } else {
                this.data.map(item => {
                    if (item[this.props.value] === this.modelValue) {
                        this.curValue = item[this.props.label];
                    }
                    return item;
                });
            }
        },
        currentChange(val) {
            const start = this.page.size * (val - 1);
            const end = this.page.size * this.page.current;
            this.showList = this.page.list.slice(start, end);
        },
        optionClick(val) {
            this.curValue = val[this.props.label];
            const value = val[this.props.value];
            this.$emit("update:modelValue", value);
            this.$emit("change", value, val);
            this.hideSelectWrap();
        },
        closed() {
            this.page.current = 1;
            this.clearData();
        },
        clearData() {
            this.curValue = null;
            this.$emit("update:modelValue", null, null);
            this.page.list = this.data.map(item => {
                item.selected = false;
                return item;
            });
            // 初始化showList
            if (this.hasPage) {
                this.page.total = this.page.list.length;
                this.showList = this.page.list.slice(0, this.page.size);
            } else {
                this.showList = this.page.list;
            }
        },
        filterTitle(v, s = 1) {
            let target = "";
            const dropWidth = this.dropWidth ? this.dropWidth : this.wrapWidth;
            const wid = dropWidth;
            const len = v.length;
            let tLen = len * 10;

            if (this.titleText2) {
                if (s == 1) {
                    target = (wid - 26) * 0.55 - 10 < tLen ? v : "";
                } else {
                    tLen = len * 8;
                    target = (wid - 26) * 0.45 - 20 < tLen ? v : "";
                }
            } else {
                target = wid - 26 < tLen ? v : "";
            }
            return target;
        },
    },
    computed: {
        getDropWidth() {
            if (this.dropWidth) {
                return `${this.dropWidth}px`;
            } else {
                return `${this.wrapWidth}px`;
            }
        },
        getOptionItemWidth() {
            if (!this.titleText2) {
                return {
                    width: "100%",
                    paddingRight: 0,
                };
            } else {
                return "";
            }
        },
    },
    watch: {
        data: {
            handler() {
                this.initData();
            },
            deep: true,
            immediate: true,
        },
        modelValue() {
            this.initValue();
        },
    },
};
</script> -->

<style lang="scss" scope>
.mx-select {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    font-size: 13px;
    --el-popover-padding: 0;
    .el-pagination__jump {
        margin-left: 0 !important;
    }
    &__input {
        position: relative;
        box-sizing: border-box;
    }

    &-input__inner {
        width: 100%;
    }
    &__icon-close {
        position: absolute;
        right: 5px;
        top: 8px;
        z-index: 2;
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        font-size: 16px;
        cursor: pointer;
    }
    &__wrapper {
        background-color: #fff;
        min-width: 180px;
        box-sizing: border-box;
        &-header {
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            line-height: 36px;
            padding: 0 10px;
            border-bottom: 1px solid #dcdfe6;
            box-sizing: border-box;
            div {
                padding-right: 10px;
                width: 55%;
                box-sizing: border-box;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                + div {
                    width: 45%;
                    margin-left: 10px;
                }
            }
        }
        &-body {
            max-height: 288px;
            overflow-y: auto;
            box-sizing: border-box;
            font-size: 12px;
        }
        &-footer {
            overflow: hidden;
            border-top: 1px solid #dcdfe6;
            line-height: 1em;
            padding: 6px 0 0;
            box-sizing: border-box;
            & :deep(.btn-prev) {
                padding-right: 6px;
                min-width: auto;
            }
            & :deep(.btn-next) {
                padding-left: 6px;
                min-width: auto;
            }
        }
    }
    &__empty {
        padding: 0 20px;
        color: #999999;
    }
    &-option {
        width: 100%;
        padding: 0 10px;
        color: #606266;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        &:hover {
            background-color: #f5f7fa;
        }
        > div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 36px;
            height: 36px;
            padding-right: 10px;
            width: 55%;
            box-sizing: border-box;
            + div {
                padding-left: 10px;
                width: 45%;
            }
        }
    }
}
.is-disabled {
    input {
        background-color: #f5f7fa !important;
    }
}
.is-selected {
    background-color: #f5f7fa;
}
</style>
