<template>
    <el-button v-bind="$attrs" @click="change" :loading="loading"> <slot>导出</slot> </el-button>
</template>

<script>
import { post } from "@/utils/request.js";
import { downloadByBinary } from "@/utils/assets.js";

export default {
    name: "FetchExport",
    props: {
        action: { type: String, default: "" },
        fileName: { type: String, default: "默认导出名称" },
        fileType: { type: String, default: "xlsx" },
        data: {
            type: Object,
            default() {
                return {};
            },
        },
        config: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        change() {
            this.loading = true;
            const url = this.action;
            const data = this.data;
            const conf = { ...this.config, responseType: "blob" };
            post(url, data, conf)
                .then(res => {
                    this.loading = false;
                    if (res.status === 200) {
                        downloadByBinary(res.data, this.fileName, this.fileType);
                        this.$emit("success");
                    } else {
                        this.$message.error(res.statusText);
                        this.$emit("error");
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit("complete");
                });
        },
    },
};
</script>
