export default [
    {
        name: "manualRecord",
        path: "/bill/send/manualRecord",
        component: () =>
            import(/* webpackChunkName: "manualRecord" */ "@/views/bill/send/manual-record/manual-record.vue"),
        meta: {
            title: "杂费账单录入",
        },
    },
    {
        name: "manualRecordContract",
        path: "/bill/send/manualRecordContract",
        component: () =>
            import(/* webpackChunkName: "manualRecord" */ "@/views/bill/send/manual-record/manual-record-contract.vue"),
        meta: {
            title: "杂费账单录入-承包区",
        },
    },
    {
        name: "manualRecordAdd",
        path: "/bill/send/manualRecord/add",
        component: () =>
            import(/* webpackChunkName: "manualRecord" */ "@/views/bill/send/manual-record/manual-record-add.vue"),
        meta: {
            title: "杂费账单录入新增",
        },
    },
    {
        name: "manualRecordEdit",
        path: "/bill/send/manualRecord/edit",
        component: () =>
            import(/* webpackChunkName: "manualRecord" */ "@/views/bill/send/manual-record/manual-record-edit.vue"),
        meta: {
            title: "杂费账单录入修改",
        },
    },
    {
        name: "manualRecordContractAdd",
        path: "/bill/send/manualRecordContract/add",
        component: () =>
            import(
                /* webpackChunkName: "manualRecord" */ "@/views/bill/send/manual-record/manual-record-contract-add.vue"
            ),
        meta: {
            title: "杂费账单录入新增-承包区",
        },
    },
    {
        name: "manualRecordContractEdit",
        path: "/bill/send/manualRecordContract/edit",
        component: () =>
            import(
                /* webpackChunkName: "manualRecord" */ "@/views/bill/send/manual-record/manual-record-contract-edit.vue"
            ),
        meta: {
            title: "杂费账单录入修改-承包区",
        },
    },
    {
        name: "regularDeduction",
        path: "/bill/send/manualRecord/regularDeduction",
        component: () =>
            import(
                /* webpackChunkName: "regularDeduction" */ "@/views/bill/send/manual-record/manual-record-regular-deduction.vue"
            ),
        meta: {
            title: "定时扣款",
        },
    },
    {
        name: "regularDeductionAdd",
        path: "/bill/send/manualRecord/regularDeduction/add",
        component: () =>
            import(
                /* webpackChunkName: "regularDeduction" */ "@/views/bill/send/manual-record/manual-record-regular-deduction-add.vue"
            ),
        meta: {
            title: "定时扣款新增",
        },
    },
    {
        name: "regularDeductionEdit",
        path: "/bill/send/manualRecord/regularDeduction/edit",
        component: () =>
            import(
                /* webpackChunkName: "regularDeduction" */ "@/views/bill/send/manual-record/manual-record-regular-deduction-edit.vue"
            ),
        meta: {
            title: "定时扣款修改",
        },
    },
    {
        name: "regularDeductionContract",
        path: "/bill/send/manualRecord/regularDeductionContract",
        component: () =>
            import(
                /* webpackChunkName: "regularDeduction" */ "@/views/bill/send/manual-record/manual-record-regular-deduction-contract.vue"
            ),
        meta: {
            title: "定时扣款(业务员)",
        },
    },
    {
        name: "regularDeductionContractAdd",
        path: "/bill/send/manualRecord/regularDeductionContract/add",
        component: () =>
            import(
                /* webpackChunkName: "regularDeduction" */ "@/views/bill/send/manual-record/manual-record-regular-deduction-contract-add.vue"
            ),
        meta: {
            title: "定时扣款新增(业务员)",
        },
    },
    {
        name: "regularDeductionContractEdit",
        path: "/bill/send/manualRecord/regularDeductionContract/edit",
        component: () =>
            import(
                /* webpackChunkName: "regularDeduction" */ "@/views/bill/send/manual-record/manual-record-regular-deduction-contract-edit.vue"
            ),
        meta: {
            title: "定时扣款修改(业务员)",
        },
    },
];
