import { typeOf } from "./type.js";
const storage = {
    set: (key, val) => {
        const type = typeOf(val);
        let item;
        if (type === "Object" || type === "Array") {
            item = JSON.stringify(val);
        } else {
            item = val;
        }
        localStorage.setItem(key, item);
    },
    get: key => {
        let item = localStorage.getItem(key);
        if (item !== null || item !== undefined)
            try {
                item = JSON.parse(item);
            } catch (error) {
                // throw error;
            }
        return item;
    },
    remove: key => {
        localStorage.removeItem(key);
    },
    clear: () => {
        localStorage.clear();
    },
};

export default storage;
