<template>
    <i class="iconfont" :class="name" @click="handleClick"></i>
</template>

<script>
export default {
    name: "mxIcon",
    props: { name: String },
    methods: {
        handleClick(ev) {
            this.$emit("click", ev);
        },
    },
};
</script>
