<template>
    <el-table
        class="mx-table"
        :data="data"
        :border="border"
        :size="size"
        :height="height"
        :max-height="mHeight"
        :cell-class-name="cellClassName"
        :header-cell-class-name="headerCellClassName"
        :row-class-name="rowClassName"
        :cell-style="cellStyle"
        :tooltip-effect="tooltipEffect"
        :show-summary="showSummary"
        :summary-method="summaryMethod"
        :span-method="spanMethod"
        :stripe="true"
        :row-key="rowKey"
        :tree-props="treeProps"
        :sort-change="sortChange"
        scrollbar-always-on
        v-bind="$attrs"
    >
        <slot> </slot>
        <template #empty>
            <el-empty description="暂无数据" />
        </template>
    </el-table>
</template>

<script>
export default {
    name: "MxTable",
    props: {
        data: Array,
        height: String,
        maxHeight: { type: String, default: "544" },
        border: { type: Boolean, default: true },
        size: { type: String, default: "default" },
        headerCellClassName: { type: String, default: "el-table-header-bgc" },
        cellClassName: { type: [String, Function], default: null },
        rowClassName: { type: [String, Function], default: null },
        headerCellStyle: {
            type: [Object, Function],
            default() {
                return {};
            },
        },
        tooltipEffect: { type: String, default: "light" },
        highlightCurrentRow: { type: Boolean },
        showSummary: { type: Boolean, default: false },
        summaryMethod: Function,
        cellStyle: Function,
        spanMethod: Function,
        sortChange: Function,
        rowKey: [Function, String],
        treeProps: Object,
    },
    computed: {
        mHeight() {
            if (this.showSummary) {
                return `${Number(this.maxHeight) + 36}px`;
            } else {
                return `${this.maxHeight}px`;
            }
        },
    },
};
</script>

<style lang="scss">
.el-table-header-bgc {
    background: #e8f8ff !important;
    color: black;
}
.el-table {
    --el-table-header-bg-color: #e8f8ff;

    border-radius: 8px 8px 0px 0px;
    &.mx-table {
        & .el-table__header th {
            padding: 7px 0;
        }
        & .el-table__body td {
            padding: 7px 0;
        }
        & .el-table__footer td {
            padding: 7px 0;
        }
        & .el-button--text {
            padding: 0;
        }
    }
}
</style>
