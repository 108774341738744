<template>
    <div class="mx-feedback">
        <p class="mx-feedback-title">意见反馈<i class="mx-feedback-desc">您的宝贵意见，对我们很重要</i></p>
        <el-form :model="formItem" :rules="rules" label-position="right" label-width="94px" ref="feedbackForm">
            <el-form-item label="反馈类别：" prop="type">
                <el-radio-group v-model="formItem.type">
                    <el-radio v-for="item in types" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="反馈详情：" prop="content">
                <el-input
                    v-model="formItem.content"
                    rows="5"
                    type="textarea"
                    placeholder="请输入您想反馈的问题描述，描述的越详细越能快速帮您解决问题。如无法描述，也可拨打客服热线：400-4000-4001(周一到周五8:30-18:00)"
                >
                </el-input>
            </el-form-item>
            <el-form-item label="联系方式：" prop="phone">
                <el-input
                    v-model="formItem.phone"
                    maxlength="11"
                    placeholder="如不方便，也可留下您的电话，我们将主动联系您"
                />
            </el-form-item>
            <div class="mx-feedback-button">
                <el-button type="primary" @click="add">提交反馈</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
import { add } from "@/api/individual-center/message/feedback.js";
import { encodeRsa } from "@/utils/assets.js";

export default {
    name: "Feedback",
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const checkPhone = (rule, value, callback) => {
            if (value) {
                const reg = /^1(([38]\d)|(4[5-9])|(5[^4])|(6[56])|(7[0-8])|(9[189]))\d{8}$/;
                if (reg.test(value)) {
                    callback();
                } else {
                    callback(new Error("请输入正确的手机号"));
                }
            } else {
                callback(new Error("请输入手机号"));
            }
        };
        return {
            formItem: {
                type: 1,
                content: "",
                phone: "",
            },
            types: [
                {
                    label: "系统问题",
                    value: 1,
                },
                {
                    label: "业务问题",
                    value: 2,
                },
                {
                    label: "其它疑问",
                    value: 3,
                },
            ],
            rules: {
                content: [
                    { required: true, message: "反馈详情不能为空", trigger: ["change", "blur"] },
                    { mix: 1, max: 200, message: "反馈详情不能大于200个字符", trigger: ["change", "blur"] },
                ],
                phone: [{ required: true, validator: checkPhone, trigger: ["change", "blur"] }],
            },
        };
    },
    mounted() {
        this.formItem.phone = this.$store.getters.userInfo.contactInformation;
    },
    methods: {
        add() {
            this.$refs.feedbackForm.validate(valid => {
                if (valid) {
                    const data = {
                        type: this.formItem.type,
                        content: this.formItem.content,
                        phone: encodeRsa(this.formItem.phone),
                        sender: this.$store.getters.userInfo.name,
                        senderId: this.$store.getters.userInfo.userId,
                    };
                    add(data)
                        .then(res => {
                            if (res.code === "000000") {
                                this.$message.success(res.message);
                                this.resetFields();
                                this.$emit("change", false);
                            } else {
                                this.$message.error(res.message);
                            }
                        })
                        .catch(() => {
                            this.$emit("change", false);
                        });
                }
            });
        },
        resetFields() {
            this.$refs.feedbackForm.resetFields();
        },
    },
};
</script>

<style lang="scss" scoped>
.mx-feedback {
    &-title {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        margin-bottom: 20px;
    }
    &-desc {
        color: #909399;
        font-size: 13px;
        font-style: oblique;
        margin-left: 24px;
    }
    &-button {
        display: flex;
        justify-content: center;
    }
}
</style>
@/api/individual-center/message/feedback.js
