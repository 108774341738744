import axios from "axios";
import { ElMessage as Message } from "element-plus";
import { getElMs } from "@/utils/utils";
import Router from "@/router/index.js";
import Store from "@/store/index.js";

const conf = { baseURL: process.env.VUE_APP_AXIOS_BASE_URL, timeout: 600 * 1000, withCredentials: true };
export var baseURL = conf.baseURL;

export const _axios = axios.create(conf);

_axios.interceptors.request.use(
    config => {
        const excludesApi = [`/getcode`, `/charging/sendAndLogin/smsverification`];
        if (!excludesApi.includes(config.url)) {
            config.headers["site-assistance-token"] = Store.state.token;
            const { admin, dotId, stationId } = Store.state.userInfo;
            const RANDOM = window.btoa(`${admin}&${dotId}&${stationId}`);
            config.headers["RANDOM"] = RANDOM || "";
        }

        if (config.method === "post") {
            if (Store.state.websocketInstance) {
                const url = Store.state.websocketInstance.instance.url;
                const excludeTimestampList = [
                    "/charging/user/userUpdCollect",
                    "/charging/sysNotice/deleteNoticeUpdate",
                ];
                if (!excludeTimestampList.includes(config.url)) {
                    config.data["timestamp"] = url?.split("_")[1];
                }
            }
            const condition = [];
            if (config.data.item) {
                for (let i in config.data.item) {
                    condition.push({ column: i, value: config.data.item[i] });
                }
                config.data.condition = condition;
                delete config.data.item;
            }
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
_axios.interceptors.response.use(
    response => {
        if (response.status === 200) {
            var res = response.data;
            if (res.code == "000000" && res.data) {
                if (res.data.list && Object.prototype.toString.call(res.data.list) === "[object Array]") {
                    res.data.list = res.data.list.map(cur => {
                        Object.keys(cur).map(item => {
                            cur[item] = cur[item] == null ? "--" : cur[item];
                        });
                        return cur;
                    });
                } else if (res.data.data && Object.prototype.toString.call(res.data.data) === "[object Array]") {
                    res.data.data = res.data.data.map(cur => {
                        Object.keys(cur).map(item => {
                            cur[item] = cur[item] == null ? "--" : cur[item];
                        });
                        return cur;
                    });
                }
            } else if (res.code == "000001") {
                Message.warning(res.message);
            } else if (["010011"].includes(res.code)) {
                Router.push("/release");
                getElMs("当前正在发版，请稍后再登录") > 0 ? null : Message.error("当前正在发版，请稍后再登录");
            } else if (["010004", "010001", "010003", "010005", "010008"].includes(res.code)) {
                setTimeout(() => {
                    getElMs("该账号长时间未操作或在其他设备登录！") > 0
                        ? null
                        : Message.error("该账号长时间未操作或在其他设备登录！");
                }, 1000);
                Router.push("/login");
                Store.commit("setToastCount", 2);
                Store.commit("userInfo", {});
                Store.commit("pageTabs", []);
                Store.commit("modules", "");
                Store.commit("websocketInstance", null);
                Store.commit("menusIn", {});
                Store.commit("menus", []);
                return Promise.reject(res);
            }
            if (!response.headers["content-type"].includes("application/json")) {
                return response;
            } else {
                return response.data;
            }
        } else {
            return response;
        }
    },
    error => {
        const { response } = error;
        if (response) {
            if (response.status == 500) {
                Message.error("服务端异常");
            } else if (response.status == 405) {
                setTimeout(() => {
                    getElMs("该账号长时间未操作或在其他设备登录！") > 0
                        ? null
                        : Message.error("该账号长时间未操作或在其他设备登录！");
                }, 1000);
                Router.push("/login");
                Store.commit("setToastCount", 2);
                Store.commit("userInfo", {});
                Store.commit("pageTabs", []);
                Store.commit("modules", "");
                Store.commit("websocketInstance", null);
                Store.commit("menusIn", {});
                Store.commit("menus", []);
            }
        }
        return Promise.reject(error);
    }
);

export const get = (url, params, config) => {
    const conf = config || {};
    Object.assign(conf, { url, params, method: "get" });
    return _axios(conf);
};

export const put = (url, params, config) => {
    const conf = config || {};
    return _axios.put(url, params, conf);
};

export const post = (url, data, config = {}) => {
    const { format = true, ...conf } = config;

    Object.assign(conf, { data: format ? formatData(data) : formatData2(data), url, method: "post" });
    return _axios(conf);
};

export const del = (url, params, config) => {
    const conf = config || {};
    return _axios.delete(url, { params }, conf);
};

function formatData(val) {
    val = val || {};
    for (var key in val) {
        if (Array.isArray(val[key])) {
            val[key] = val[key].filter(function (v) {
                return v === undefined || v === null || val[key] === "" ? false : true;
            });
        }
        if (typeof val[key] == "object") {
            formatData(val[key]);
        }
        if (val[key] === undefined || val[key] === null || val[key] === "") {
            delete val[key];
        }
    }
    return val || {};
}
function formatData2(val) {
    val = val || {};
    for (var key in val) {
        if (Array.isArray(val[key])) {
            val[key] = val[key].map(function (v) {
                if (v === undefined || v === null) {
                    val[key] = "";
                }
            });
        }
        if (typeof val[key] == "object") {
            formatData(val[key]);
        }
        if (val[key] === undefined || val[key] === null) {
            val[key] = "";
        }
    }
    return val || {};
}
