import { post } from "@/utils/request.js";

/**
 * 下载中心的列表接口
 * @param {*} data
 */
export const downloadList = (data = {}) => {
    const url = "/charging/tDownloadCenter/selectDownloadCenterPage";
    return post(url, data);
};
/**
 * 获取模版列表
 * @param {*} data
 */
export const getTemplateList = data => {
    const url = "/charging/tDownloadCenter/selectTemplateName";
    return post(url, data);
};
