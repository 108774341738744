<template>
    <el-select-v2
        :remote="remote"
        :options="result"
        :remote-method="remoteMethod"
        popper-class="test-v2"
        v-bind="$attrs"
    >
        <template v-slot="{ item }">
            <span v-if="typeof searchKey === 'string'">{{ item.label }}</span>
            <p class="flex width-block" v-else>
                <span class="padding-right-small text_overflow_1" style="flex: auto">{{ item.label }}</span>
                <span class="padding-left-small text_overflow_1" style="flex: 0 0 100px">{{ item.code }}</span>
            </p>
        </template>
    </el-select-v2>
</template>

<script>
export default {
    props: {
        remote: {
            type: Boolean,
            default: false,
        },
        searchKey: {
            type: [Array, String],
            default() {
                return "label";
            },
            validator(value) {
                if (typeof value === "string") {
                    return true;
                } else if (value.length === 0) {
                    return false;
                } else {
                    return value.every(item => typeof item === "string");
                }
            },
        },
        options: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            optionsRaw: [],
            result: [],
        };
    },
    methods: {
        remoteMethod(keyword) {
            if ([null, ""].includes(keyword)) {
                this.result = [...this.optionsRaw];
            } else {
                const reg = new RegExp(`${keyword}`, "i");
                this.result = this.optionsRaw.filter(c => {
                    if (typeof this.searchKey === "string") {
                        return reg.test(c[this.searchKey]);
                    } else {
                        const flag = this.searchKey.some(item => {
                            const f = reg.test(c[item]);
                            return f;
                        });
                        return flag;
                    }
                });
            }
        },
    },
    watch: {
        options: {
            handler(val) {
                this.optionsRaw = val;
                this.remoteMethod("");
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>

<style lang="scss">
.test-v2 {
    & .el-select-dropdown__list {
        width: 280px !important;
    }
}
</style>
