export default [
    {
        name: "rechargeWithdrawal",
        path: "/bill/salesman-settlement/recharge-withdrawal",
        component: () =>
            import(
                /* webpackChunkName: "salesman-settlement" */ "@/views/bill/salesman-settlement/recharge-withdrawal.vue"
            ),
        meta: { title: "转入转出" },
    },
    {
        name: "reimburseManagement",
        path: "/bill/salesman-settlement/reimburse-management",
        component: () =>
            import(
                /* webpackChunkName: "salesman-settlement" */ "@/views/bill/salesman-settlement/reimburse-management.vue"
            ),
        meta: { title: "报销管理" },
    },
    {
        name: "fine",
        path: "/bill/salesman-settlement/fine",
        component: () =>
            import(/* webpackChunkName: "salesman-settlement" */ "@/views/bill/salesman-settlement/fine.vue"),
        meta: { title: "考核费用" },
    },
    {
        name: "otherCost",
        path: "/bill/salesman-settlement/other",
        component: () =>
            import(/* webpackChunkName: "salesman-settlement" */ "@/views/bill/salesman-settlement/other.vue"),
        meta: { title: "其它费用" },
    },
    {
        name: "payAsYouGo",
        path: "/bill/salesman-settlement/payAsYouGo",
        component: () =>
            import(/* webpackChunkName: "payAsYouGo" */ "@/views/bill/salesman-settlement/pay-as-you-go.vue"),
        meta: { title: "现付到付代收综合", target: "_blank" },
    },
    {
        name: "payAsYouGoSum",
        path: "/bill/salesman-settlement/payAsYouGo/sum",
        component: () =>
            import(/* webpackChunkName: "payAsYouGo" */ "@/views/bill/salesman-settlement/pay-as-you-go-sum.vue"),
        meta: { title: "现付到付代收综合-网点汇总", target: "_blank" },
    },
    {
        name: "payAsYouGoSumContract",
        path: "/bill/salesman-settlement/payAsYouGo/sumContract",
        component: () =>
            import(
                /* webpackChunkName: "payAsYouGo" */ "@/views/bill/salesman-settlement/pay-as-you-go-sum-contract.vue"
            ),
        meta: { title: "现付到付代收综合-业务员汇总", target: "_blank" },
    },
    {
        name: "dispatchBill",
        path: "/bill/salesman-settlement/dispatchBill",
        component: () =>
            import(/* webpackChunkName: "dispatchBill" */ "@/views/bill/salesman-settlement/dispatch/dispatchBill.vue"),
        meta: { title: "派件管理", target: "_blank" },
    },
    {
        name: "dispatchBillSum",
        path: "/bill/salesman-settlement/dispatchBill/sum",
        component: () =>
            import(
                /* webpackChunkName: "dispatchBill" */ "@/views/bill/salesman-settlement/dispatch/dispatchBillSum.vue"
            ),
        meta: { title: "派费账单网点汇总" },
    },
    {
        name: "dispatchBillSumContract",
        path: "/bill/salesman-settlement/dispatchBill/sumContract",
        component: () =>
            import(
                /* webpackChunkName: "dispatchBill" */ "@/views/bill/salesman-settlement/dispatch/dispatchBillSumContract.vue"
            ),
        meta: { title: "派费账单业务员汇总" },
    },
    {
        name: "examineBill",
        path: "/bill/materiel/examine",
        component: () =>
            import(/* webpackChunkName: "examineBill" */ "@/views/bill/salesman-settlement/materiel/examine.vue"),
        meta: { title: "收款审核(客户)" },
    },
    {
        name: "examineContractBill",
        path: "/bill/materiel/examineContract",
        component: () =>
            import(
                /* webpackChunkName: "examineBill" */ "@/views/bill/salesman-settlement/materiel/examineContract.vue"
            ),
        meta: { title: "收款审核(承包区)" },
    },
    {
        name: "examineHistoryBill",
        path: "/bill/materiel/examine/history",
        component: () =>
            import(
                /* webpackChunkName: "examineBill" */ "@/views/bill/salesman-settlement/materiel/examineHistory.vue"
            ),
        meta: { title: "收款审核历史" },
    },
    {
        name: "examineHistoryDetailBill",
        path: "/bill/materiel/examine/history/detail",
        component: () =>
            import(
                /* webpackChunkName: "examineBill" */ "@/views/bill/salesman-settlement/materiel/examineHistoryDetail.vue"
            ),
        meta: { title: "收款审核详情" },
    },
    {
        name: "examineHistoryContractBill",
        path: "/bill/materiel/examine/historyContract",
        component: () =>
            import(
                /* webpackChunkName: "examineBill" */ "@/views/bill/salesman-settlement/materiel/examineHistoryContract.vue"
            ),
        meta: { title: "收款审核历史(承包区)" },
    },
    {
        name: "examineHistoryContractDetailBill",
        path: "/bill/materiel/examine/historyContract/detail",
        component: () =>
            import(
                /* webpackChunkName: "examineBill" */ "@/views/bill/salesman-settlement/materiel/examineHistoryDetail.vue"
            ),
        meta: { title: "收款审核详情(承包区)" },
    },
    {
        name: "salesmanAccount",
        path: "/bill/salesman-settlement/account",
        component: () => import(/* webpackChunkName: "settlement" */ "@/views/bill/salesman-settlement/account.vue"),
        meta: { title: "账户管理" },
    },
    {
        name: "billSynthesis",
        path: "/bill/salesman-settlement/synthesis",
        component: () => import(/* webpackChunkName: "settlement" */ "@/views/bill/salesman-settlement/synthesis.vue"),
        meta: { title: "综合报表" },
    },
];
