/**
 * 导入数据管理
 */
export default [
    {
        name: "importRecord",
        path: "/bill/data/record",
        component: () => import(/* webpackChunkName: "dataManagement" */ "@/views/bill/data/importRecord.vue"),
        meta: { title: "导入记录" },
    },
    {
        name: "imports",
        path: "/bill/data/import",
        component: () => import(/* webpackChunkName: "dataManagement" */ "@/views/bill/data/import.vue"),
        meta: { title: "导入数据" },
    },
    {
        name: "downloadExport",
        path: "/bill/data/downloadExport",
        component: () => import(/* webpackChunkName: "dataManagement" */ "@/views/bill/data/download-export.vue"),
        meta: { title: "下载中心" },
    },
    {
        name: "expressTrackingQuery",
        path: "/bill/data/express-tracking-query",
        component: () =>
            import(/* webpackChunkName: "dataManagement" */ "@/views/bill/data/express-tracking-query.vue"),
        meta: { title: "快件跟踪查询" },
    },
];
