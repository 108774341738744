<template>
    <router-view />
</template>

<style lang="scss">
.font-family {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial,
        sans-serif;
}
html,
body,
#app {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    font-size: 14px;
}
#app {
    font-family: AlibabaPuHuiTi-Heavy, AlibabaPuHuiTi;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}

@each $type in margin, padding {
    .#{$type} {
        #{$type}: 12px;
        @each $placement in top, right, bottom, left {
            &-row {
                #{$type}-left: 12px;
                #{$type}-right: 12px;
                &-large {
                    #{$type}-left: 24px;
                    #{$type}-right: 24px;
                }
                &-small {
                    #{$type}-left: 8px;
                    #{$type}-right: 8px;
                }
            }
            &-col {
                #{$type}-top: 12px;
                #{$type}-bottom: 12px;
                &-large {
                    #{$type}-top: 24px;
                    #{$type}-bottom: 24px;
                }
                &-small {
                    #{$type}-top: 8px;
                    #{$type}-bottom: 8px;
                }
            }
            &-#{$placement} {
                #{$type}-#{$placement}: 12px;
                &-large {
                    #{$type}-#{$placement}: 24px;
                }
                &-small {
                    #{$type}-#{$placement}: 8px;
                }
            }
        }
    }
}

/* 分页组件容器的样式 */
.pagination-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 12px;
    background-color: #fff;
}
/* 自定义ElDialog样式 */
.el-dialog {
    --el-dialog-padding-primary: 16px !important;
    border-radius: 8px !important;
    &__header {
        border-bottom: 1px solid #e8eaec;
        padding-bottom: 16px !important;
    }
    &__title {
        color: #17233d !important;
    }
    &__footer {
        border-top: 1px solid #e8eaec;
        padding-top: 16px !important;
    }
}
/* title font size */
.h1 {
    font-size: 20px;
    font-weight: 600;
}
.h2 {
    font-size: 18px;
    font-weight: 600;
}
.h3 {
    font-size: 16px;
    font-weight: 600;
}

.page-main {
    background-color: #fff;
}

/* 常用的宽度 */
.width-block {
    width: 100%;
}
/* tooltip 宽度最大值 */
.el-popper {
    max-width: 760px;
}

/** 鼠标指针状态 */
.cursor-pointer {
    cursor: pointer;
}
.cursor-default {
    cursor: default;
}

/** custom notification style */
.el-notification.nebula-progress-notification {
    &-error {
        --el-notification-title-color: var(--el-color-danger);
        --el-notification-content-color: var(--el-color-danger);
    }
}
</style>
