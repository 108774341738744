import { get, post } from "@/utils/request.js";

/**
 * 查询导入数据的批次列表
 * @param {Object)} data
 */
export const importRecord = data => {
    const url = "/charging/data/selectImportRecordPage";
    return post(url, data);
};

/**
 * 查看错误信息
 * @param {Object)} data
 */
export const importRecordError = data => {
    const url = "/charging/data/getErrMsg";
    return get(url, data);
};

/**
 * 删除
 * @param {Object)} data
 */
export const del = data => {
    const url = "/charging/data/deleteImportRecordById";
    return get(url, data);
};
