import { get, post } from "@/utils/request.js";

/**
 * 通过手机号获取验证码
 * @param {*} data
 */
export const getCodeByTel = data => {
    const url = "/login/related/relevance";
    return get(url, data);
};

/**
 * 校验验证码和手机号是否匹配--忘记密码第一步操作
 * @param {*} data
 */
export const checkTelAndCode = data => {
    const url = "/login/related/inspect";
    return post(url, data);
};

/**
 * 重新设置密码--忘记密码操作第二步
 * @param {*} data
 */
export const resetPassword = data => {
    const url = "/login/related/newpassword";
    return post(url, data);
};

/**
 * 重新设置密码--忘记密码只有一步原来遗弃掉
 * @param {*} data
 */
export const resetPassword1 = data => {
    const url = "/login/related/confirmPassword";
    return post(url, data);
};

//未登陆修改密码
export const editPwdByUnlogin = data => {
    return post("/UpDefaultPwd", data);
};
//登陆后修改密码
export const editPwdByLogin = data => {
    return post("/LgUPassword", data);
};
