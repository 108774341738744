export default [
    {
        name: "accountsReceivable",
        path: "/bill/send/report/accounts-receivable",
        component: () =>
            import(
                /* webpackChunkName: "accountsReceivable" */ "@/views/bill/send/accounts-receivable/accounts-receivable.vue"
            ),
        meta: { title: "应收账报表", target: "_blank" },
    },
    {
        name: "accountsReceivableNoPrice",
        path: "/bill/send/report/accounts-receivable/no-fee",
        component: () =>
            import(
                /* webpackChunkName: "accountsReceivable" */ "@/views/bill/send/accounts-receivable/accounts-receivable-no-fee.vue"
            ),
        meta: { title: "应收账报表无费用", target: "_blank" },
    },
    {
        name: "accountsReceivableSum",
        path: "/bill/send/report/accounts-receivable/sum",
        component: () =>
            import(
                /* webpackChunkName: "accountsReceivable" */ "@/views/bill/send/accounts-receivable/accounts-receivable-sum.vue"
            ),
        meta: { title: "应收账报表汇总", target: "_blank" },
    },

    {
        name: "billAdjustment",
        path: "/bill/data/accounts-receivable/bill-adjustment",
        component: () =>
            import(
                /* webpackChunkName: "billAdjustment" */ "@/views/bill/send/accounts-receivable/bill-adjustment.vue"
            ),
        meta: { title: "账单调整" },
    },
    {
        name: "billAdjustmentAdd",
        path: "/bill/data/accounts-receivable/bill-adjustment/add",
        component: () =>
            import(
                /* webpackChunkName: "billAdjustment" */ "@/views/bill/send/accounts-receivable/bill-adjustment-add.vue"
            ),
        meta: { title: "账单调整新增" },
    },
];
