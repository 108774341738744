export default [
    {
        name: "financeVoucherAdd",
        path: "/finance/voucher/add",
        component: () => import(/* webpackChunkName: "costQuotation" */ "@/views/finance/voucher/add.vue"),
        meta: { title: "录凭证", keepAlive: true }, // 需要缓存
    },
    {
        name: "financeVoucherQuery",
        path: "/finance/voucher/query",
        component: () => import(/* webpackChunkName: "costQuotation" */ "@/views/finance/voucher/query.vue"),
        meta: { title: "查凭证" },
    },
    {
        name: "financeVoucherEdit",
        path: "/finance/voucher/edit",
        component: () => import(/* webpackChunkName: "costQuotation" */ "@/views/finance/voucher/edit.vue"),
        meta: { title: "凭证", keepAlive: true },
    },
    {
        name: "financeAssetVoucher",
        path: "/finance/fixedAsset/voucher",
        component: () => import(/* webpackChunkName: "manualRecord" */ "@/views/finance/voucher/assetCard.vue"),
        meta: { title: "凭证" },
    },
    {
        name: "financeVoucherWriteDowns",
        path: "/finance/voucher/writeDowns",
        component: () => import(/* webpackChunkName: "costQuotation" */ "@/views/finance/voucher/writeDowns.vue"),
        meta: { title: "凭证冲销", keepAlive: true },
    },
    {
        name: "financeCheckVoucher",
        path: "/finance/check/voucher",
        component: () => import(/* webpackChunkName: "financeDashboard" */ "@/views/finance/voucher/check_out.vue"),
        meta: { title: "凭证", keepAlive: true },
    },
    {
        name: "financeAssetVoucher",
        path: "/finance/fixedAsset/voucher",
        component: () => import(/* webpackChunkName: "manualRecord" */ "@/views/finance/voucher/assetCard.vue"),
        meta: { title: "凭证" },
    },
];
